import React from 'react'
import { Modal } from '@mui/material'
import { WalletName } from '@aptos-labs/wallet-adapter-react'

import { ChainOptions, Chains } from '../../types'
import { useMultichainWallet } from '../../hooks'
import { ModalHeaderContainer } from '../ui'
import { translate } from '../../localization'

const SelectMultiChainWallet = ({ selectWalletVis, onHide }) => {
  const { wallets, select, connect } = useMultichainWallet()

  const onWalletSelect = async (chainType: ChainOptions, walletName: string | WalletName) => {
    if (chainType === Chains.solana && select) {
      select(walletName as WalletName)
      try {
        await connect(Chains.solana)
      } catch (e) {
        console.log(e)
      }
    } else {
      connect(Chains.aptos, walletName)
    }
    onHide()
  }

  return (
    <Modal
      open={selectWalletVis}
      onClose={onHide}
      disableEnforceFocus={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-root-quests"
    >
      <div className="select-wallet-modal">
        <ModalHeaderContainer
          title={translate('connectYourWallet')}
          onClose={onHide}
        />
        <div className="modal-container">
          <div className="modal-body-container modal-body-select-wallet">
            {wallets.map((multichainWallet) => (
              <div
                key={multichainWallet.icon}
                className="wallet-container"
                onClick={() => onWalletSelect(multichainWallet.chain, multichainWallet.name)}
              >
                <div>
                  <img src={multichainWallet.icon} alt="wallet" width="30" />
                  <span>{multichainWallet.name}</span>
                </div>
                <span className="chain-name">{multichainWallet.chain.toUpperCase()}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default SelectMultiChainWallet
