/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Modal } from '@mui/material'
import { toast } from 'react-toastify'
import { useQueryClient } from 'react-query'

import QuestRow from './QuestRow'
import { Quest } from '../../types'
import CONFIG from '../../config.json'
import {
  useTokenBalances, useQuests, useConfetti, useMultichainWallet,
} from '../../hooks'
import { ModalHeaderContainer, SwitchTabs } from '../ui'
import { translate } from '../../localization'

interface ModalQuestsProps {
  isOpen: boolean;
  onHide: () => void;
  questsType: 'social' | 'harvesting';
}

const Options = {
  new: 'new',
  completed: 'completed',
} as const

type SwitchOptions = typeof Options[keyof typeof Options]

const ModalQuests = ({ isOpen, onHide, questsType }: ModalQuestsProps) => {
  const [quests, setQuests] = useState<Quest[]>([])
  const [sortedQuests, setSortedQuests] = useState<Quest[] | []>([])
  const [activeOption, setActiveOption] = useState<SwitchOptions>(Options.new)

  const { setIsRun } = useConfetti()
  const { updateBalances } = useTokenBalances()
  const { isLoading, data } = useQuests(questsType)
  const { address } = useMultichainWallet()

  const queryClient = useQueryClient()

  useEffect(() => {
    setQuests(data?.quests || [])
  }, [data?.quests, address])

  useEffect(() => {
    if (isOpen) {
      queryClient.invalidateQueries(['quests'])
    }
  }, [isOpen, queryClient])

  useEffect(() => {
    if (activeOption === Options.completed) {
      const completedQuests = quests.filter((quest) => quest.is_claimed)
      setSortedQuests([...completedQuests])
    } else {
      const newQuests = quests.filter((quest) => !quest.is_claimed)
      setSortedQuests([...newQuests])
    }
  }, [activeOption, quests])

  const onClaim = async (quest_id: string) => {
    try {
      // @todo: refactor this
      const requestHeaders: HeadersInit = new Headers()
      requestHeaders.set('Content-Type', 'application/json')
      const result = await fetch(`${CONFIG.apiURL}/submit-quest`, {
        method: 'POST',
        headers: requestHeaders,
        body: JSON.stringify({ address, quest_id }),
      })
      const questsData = await result.json()

      if (questsData.status === 'error' && questsData.description) {
        toast.error(questsData.description)
      } else if (questsData.status === 'success') {
        queryClient.invalidateQueries(['quests'])
        toast.success('Quest completed')
        setIsRun(true)
        setTimeout(() => updateBalances(), 2000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onHide}
        disableEnforceFocus={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-root-quests"
      >
        <>
          <ModalHeaderContainer
            title={`${translate(questsType)} ${translate('quests')}`}
            onClose={onHide}
          />
          <div className="modal-container">
            <div className="modal-body-container quests-body-container">
              <SwitchTabs
                active={activeOption}
                options={Object.values(Options)}
                toggleSwitch={setActiveOption}
              />
              <div className="quests-table-container">
                <table className="quests-table" cellSpacing="0" cellPadding="0">
                  <thead className="quests-table-header">
                    <tr>
                      <td>
                        <div>
                          <span className="quests-table-header-text">{translate('quest').toUpperCase()}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span className="quests-table-header-text">{translate('reward').toUpperCase()}</span>
                        </div>
                        <div />
                      </td>
                      <td />
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading && (
                      <h3 className="loading">
                        <img src="../images/loading.svg" width="32" alt="" />
                        <br />
                        {translate('loadingQuests')}
                      </h3>
                    )}
                    {sortedQuests.length > 0 ? (
                      sortedQuests.map((questInfo, index) => (
                        <QuestRow
                          {...questInfo}
                          onClaim={onClaim}
                          onHide={onHide}
                          // eslint-disable-next-line react/no-array-index-key
                          key={questInfo.quest_id + index}
                        />
                      ))

                    ) : (
                      <tr>
                        <td colSpan={3}>
                          <h3 className="loading">{translate('noCompletedQuests')}</h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

export default ModalQuests
