import React from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  NormalizedCacheObject,
} from '@apollo/client'
import { QueryClient, QueryClientProvider } from 'react-query'

import CONFIG from '../config.json'

function getGraphqlClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: new HttpLink({
      uri: CONFIG.aptosGraphQlUrl,
    }),
    cache: new InMemoryCache(),
  })
}

export interface ApiClientProviderProps {
  children: React.ReactNode;
}

const queryClient = new QueryClient()
const graphqlClient = getGraphqlClient()

export function ApiClientProvider({ children }: ApiClientProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={graphqlClient}>
        {children}
      </ApolloProvider>
    </QueryClientProvider>
  )
}
