import React, { useState } from 'react'

export interface IMapContext {
  map: any[];
  setMap: (newMap: any[]) => void;
}

const defaultMap = {
  map: [[]],
  setMap: () => {},
}

export const MapContext = React.createContext<IMapContext>(defaultMap)

export function MapProvider({ children }) {
  const [map, setMap] = useState([[]])

  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  )
}
