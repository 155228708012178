import React from 'react'

const AchievementsIcon = () => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.242 1.42354C17.1493 0.822549 16.011 0.479562 14.8667 0.367256C13.7649 0.257986 12.6631 0.151751 11.5613 0.0424805C12.7056 0.154786 13.8469 0.500808 14.9365 1.09876C20.0571 3.90943 22.1514 11.2457 19.617 17.4772C17.6258 22.3822 13.3582 25.1443 9.13611 24.7285C10.2379 24.8377 11.3397 24.944 12.4415 25.0532C16.6636 25.4691 20.9282 22.7039 22.9224 17.8019C25.4599 11.5675 23.3625 4.23421 18.242 1.42354Z" fill="url(#paint0_linear_425_1511)" />
    <path d="M14.9396 1.09878C20.0601 3.90946 22.1545 11.2457 19.62 17.4772C17.0856 23.7117 10.8814 26.4829 5.76091 23.6722C0.640387 20.8615 -1.46003 13.5283 1.07443 7.2938C3.6089 1.06236 9.81606 -1.71493 14.9396 1.09878Z" fill="url(#paint1_linear_425_1511)" />
    <path d="M15.2128 1.09876C14.3204 0.607043 13.3917 0.291374 12.4568 0.130504C12.1593 0.100151 11.8588 0.0728333 11.5614 0.0424805C12.7057 0.154786 13.8469 0.500808 14.9366 1.09876C20.0571 3.90943 22.1515 11.2457 19.617 17.4772C17.7625 22.0453 13.9319 24.7527 10.0043 24.7679C14.0291 24.8833 17.9962 22.1545 19.8963 17.4772C22.4277 11.2427 20.3334 3.90943 15.2128 1.09876Z" fill="white" />
    <path d="M15.2614 12.3657L13.2338 10.32L13.0942 8.89641L12.9182 7.11773C12.8817 6.72922 12.73 6.45907 12.5175 6.34373C12.305 6.22839 12.0319 6.26178 11.7496 6.47425L10.4566 7.44554L9.42152 8.22561L6.77475 7.70354C6.12823 7.57606 5.7124 8.09509 5.86113 8.85999L6.18894 10.5992L6.45301 11.9863L5.12659 14.8638C4.87162 15.4162 4.98696 15.914 5.36638 16.1234C5.46958 16.1811 5.59402 16.2175 5.73364 16.2266L8.42594 16.4087L10.2532 18.7065C10.3473 18.8248 10.4505 18.9129 10.5537 18.9705C10.9361 19.18 11.3398 18.9675 11.5432 18.3877L12.6146 15.3707L13.7073 14.7272L15.0732 13.9168C15.6742 13.5677 15.7592 12.8696 15.2614 12.3657Z" fill="white" />
    <path d="M15.3767 12.3687L13.3491 10.3229L13.2095 8.89934L13.0334 7.12066C12.997 6.73214 12.8452 6.462 12.6328 6.34666C12.4203 6.23132 12.1471 6.26471 11.8648 6.47718L10.5718 7.44847L9.53677 8.22854L6.89 7.70647C6.24348 7.57899 5.82765 8.09802 5.97638 8.86292L6.30419 10.6021L6.57129 11.9893L5.24487 14.8667C4.98991 15.4191 5.10525 15.9169 5.48466 16.1264C5.58786 16.184 5.71231 16.2204 5.85193 16.2296L8.54423 16.4117L10.3715 18.7094C10.4656 18.8278 10.5688 18.9158 10.672 18.9735C11.0544 19.1829 11.4581 18.9704 11.6615 18.3907L12.7329 15.3736L13.8256 14.7301L15.1915 13.9197C15.7864 13.5676 15.8714 12.8695 15.3767 12.3687Z" fill="url(#paint2_linear_425_1511)" />
    <path d="M10.3654 7.94636L11.4004 7.16629L12.5356 6.31338C12.3383 6.24964 12.1046 6.29516 11.8618 6.47728L10.5687 7.44857L9.53369 8.22864L10.3654 7.94636Z" fill="#C43F00" />
    <path d="M11.5036 18.6915C11.4004 18.6368 11.2972 18.5458 11.2031 18.4274L9.37588 16.1297L6.68358 15.9476C6.54396 15.9384 6.41951 15.902 6.31631 15.8444C5.93387 15.6349 5.82156 15.1371 6.07349 14.5847L7.39991 11.7073L7.1328 10.3201L6.80499 8.58092C6.73518 8.22579 6.79285 7.9253 6.93551 7.71586L6.88695 7.70676C6.24043 7.57927 5.8246 8.09831 5.97333 8.8632L6.30114 10.6024L6.56824 11.9895L5.24182 14.867C4.98686 15.4194 5.1022 15.9172 5.48161 16.1266C5.58481 16.1843 5.70926 16.2207 5.84888 16.2298L8.54118 16.412L10.3684 18.7097C10.4625 18.828 10.5657 18.9161 10.6689 18.9737C10.9724 19.1407 11.2911 19.0344 11.5097 18.6945C11.5097 18.6945 11.5036 18.6945 11.5036 18.6915Z" fill="#C43F00" />
    <path d="M12.6207 0.215742C10.6234 -0.23955 8.57158 0.0457664 6.71399 0.959387L18.239 20.0513C18.7701 19.2773 19.2376 18.4183 19.62 17.4774C20.1512 16.1692 20.476 14.8124 20.6156 13.4587L12.6207 0.215742Z" fill="url(#paint3_linear_425_1511)" />
    <path d="M3.42981 3.48193C2.4828 4.54125 1.67541 5.8191 1.07443 7.29425C0.182051 9.49483 -0.133618 11.832 0.0545695 14.0569L5.90356 23.7455C8.98741 25.3694 12.4476 24.99 15.2522 23.0656L3.42981 3.48193Z" fill="url(#paint4_linear_425_1511)" />
    <defs>
      <linearGradient id="paint0_linear_425_1511" x1="9.1375" y1="12.568" x2="24.0006" y2="12.568" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFA705" />
        <stop offset="0.4495" stopColor="#FF9605" />
        <stop offset="1" stopColor="#FF8605" />
      </linearGradient>
      <linearGradient id="paint1_linear_425_1511" x1="3.96931" y1="-0.372876" x2="16.2258" y2="24.1398" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF8205" />
        <stop offset="0.1614" stopColor="#FFAB05" />
        <stop offset="0.2473" stopColor="#FFBB05" />
        <stop offset="0.3421" stopColor="#FFC405" />
        <stop offset="0.4987" stopColor="#FFDD05" />
        <stop offset="0.5645" stopColor="#FFEA05" />
        <stop offset="0.6358" stopColor="#FFDA05" />
        <stop offset="0.7443" stopColor="#FFC905" />
        <stop offset="0.8611" stopColor="#FFBE05" />
        <stop offset="1" stopColor="#FFBB05" />
      </linearGradient>
      <linearGradient id="paint2_linear_425_1511" x1="6.67483" y1="7.74626" x2="12.3362" y2="16.072" gradientUnits="userSpaceOnUse">
        <stop stopColor="#F17500" />
        <stop offset="0.5313" stopColor="#E05D00" />
        <stop offset="1" stopColor="#D44D00" />
      </linearGradient>
      <linearGradient id="paint3_linear_425_1511" x1="10.4455" y1="11.5204" x2="19.1381" y2="7.17407" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFFDEE" />
        <stop offset="1" stopColor="#FFFDEE" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint4_linear_425_1511" x1="1.68224" y1="18.8336" x2="10.4249" y2="12.4889" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFFDEE" />
        <stop offset="1" stopColor="#FFFDEE" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default AchievementsIcon
