import React from 'react'
import { Menu, MenuItem, MenuProps } from '@mui/material'

import { ModalStatusTypes } from '../Navigations'
import { translate } from '../../localization'

interface QuestsDropDrownProps {
  anchorEl: MenuProps['anchorEl'],
  setAnchorEl: (newAnchorEl: null) => void,
  handleModal: (modalType: ModalStatusTypes) => void;
}

const QuestsDropDrown = ({ anchorEl, setAnchorEl, handleModal }: QuestsDropDrownProps) => (
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={() => setAnchorEl(null)}
    MenuListProps={{ onMouseLeave: () => setAnchorEl(null) }}
  >
    <MenuItem
      className="dropdown-menu-item"
      onClick={() => {
        handleModal('questsHarvesting')
        setAnchorEl(null)
      }}
    >
      {translate('harvesting')}
    </MenuItem>
    <MenuItem
      className="dropdown-menu-item"
      onClick={() => {
        handleModal('questsSocial')
        setAnchorEl(null)
      }}
    >
      {translate('social')}
    </MenuItem>
  </Menu>
)

export default QuestsDropDrown
