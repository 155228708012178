import React from 'react'

interface GradientButtonProps {
  onClick: (e?: React.MouseEvent<HTMLElement>) => void;
  text: string;
  style?: React.CSSProperties;
  color?: string;
  textStyle?: React.CSSProperties;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
}

const GradientButton = ({
  onClick, text, color, style, textStyle, isDisabled, isLoading, className,
}: GradientButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    style={style}
    tabIndex={0}
    disabled={isDisabled}
    className={`${className} gradient-button ${isDisabled && 'disabled'} ${color === 'green' ? 'gradient-button-green' : 'gradient-button-pink'}`}
  >
    {isLoading && <img src="../images/loading.svg" width="32" alt="" />}
    <p style={textStyle} className="gradient-button-text">{text}</p>
  </button>
)

GradientButton.defaultProps = {
  color: 'gradient-button-pink',
  style: {},
  textStyle: {},
  isDisabled: false,
  isLoading: false,
  className: '',
}

export default GradientButton
