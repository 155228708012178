import { findRewardDistributorId } from '@cardinal/staking/dist/cjs/programs/rewardDistributor/pda'
import { getRewardDistributor } from '@cardinal/staking/dist/cjs/programs/rewardDistributor/accounts'
import { AccountData } from '@cardinal/common'
import { RewardDistributorData } from '@cardinal/staking/dist/cjs/programs/rewardDistributor'
import { useQuery } from 'react-query'
import { PublicKey } from '@solana/web3.js'
import connection from 'utils/connection'

// eslint-disable-next-line max-len
const useRewardDistributor = (stakePoolId:string) => useQuery<AccountData<RewardDistributorData> | undefined>(
  ['useRewardDistributorData', stakePoolId?.toString()],
  async () => {
    if (!stakePoolId) return

    const [rewardDistributorId] = await findRewardDistributorId(new PublicKey(stakePoolId))
    // eslint-disable-next-line @typescript-eslint/return-await, consistent-return
    return await getRewardDistributor(
      connection,
      rewardDistributorId,
    )
  },
  { enabled: !!stakePoolId },
)

export default useRewardDistributor
