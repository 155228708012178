/* eslint-disable max-len */
import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWallet as useAptosWallet } from '@aptos-labs/wallet-adapter-react'

import { translate } from 'localization'
import { ModalRoot } from '../components'
import sendReport from '../utils/reporting'
import CONFIG from '../config.json'
import useMap from '../hooks/useMap'
import fetchWorkersAndBuildings from '../utils/nfts'
import { getBuildingName } from '../utils/buildings'
import { ResourceTypes, Building, Chains } from '../types'

const {
  LAYOUT, PLOTS, plotWidth, plotHeight, plotOffset,
} = CONFIG

const getBuildingClass = (buildingName: string) => {
  if (buildingName.includes('wood-farm')) return 'timber-camp'
  if (buildingName.includes('stone-farm')) return 'stone-quary'
  if (buildingName.includes('iron-farm')) return 'iron-mine'
  if (buildingName.includes('wheat-farm')) return 'wheat-farm'
  return ''
}

const HomePage = () => {
  // quick hack to copy multi dimensial array
  const { map: globalMap, setMap } = useMap()
  const [areBuildingsFetched, setAreBuildingsFetched] = useState(false)
  const [buildingTypeClicked, setBuildingTypeClicked] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userBuildings, setUserBuildings] = useState<Building[]>([])

  const wallet = useWallet()
  const { account, connected } = useAptosWallet()

  useEffect(() => {
    console.log('Initial load map')
    setMap(JSON.parse(JSON.stringify(LAYOUT)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // aptos main hook
  useEffect(() => {
    if (account && connected) {
      console.log('[Aptos Wallet connected]', account.address)
      sendReport({
        address: account.address,
        event: 'login',
        chain: Chains.aptos,
      })
      // @todo: will add later
      // fetchNFTs()
    }
  }, [account, connected])

  // solana main hook
  useEffect(() => {
    setAreBuildingsFetched(false)
    async function fetchNFTs() {
      if (wallet && wallet.publicKey) {
        const { buildings } = await fetchWorkersAndBuildings(wallet && wallet.publicKey)
        setUserBuildings(buildings)
        console.log('[Map] All buildings in the wallet', buildings)
        if (buildings && buildings.length) {
          const newMap = JSON.parse(JSON.stringify(LAYOUT))
          /* Temp solution for alpha test. We have max +1 level of the farm */
          buildings.forEach((building) => {
            if (building.resource === 'wood') {
              console.log('[Map] Found timber camp')
              newMap[1][2] = 3
            }
            if (building.resource === 'stone') {
              console.log('[Map] Found stone quarry')
              newMap[3][0] = 8
            }
            if (building.resource === 'iron') {
              console.log('[Map] Found iron mine')
              newMap[5][1] = 12
            }
          })
          setMap(newMap)
        }
      }
      setAreBuildingsFetched(true)
    }
    if (wallet && wallet.publicKey && !wallet.connecting && !wallet.disconnecting) {
      console.log('[Solana Wallet connected]', wallet?.publicKey?.toBase58())
      sendReport({
        address: wallet.publicKey.toBase58(),
        event: 'login',
        chain: Chains.solana,
      })
      fetchNFTs()
    } else if (!wallet.connected) {
      setAreBuildingsFetched(true)
      setMap(JSON.parse(JSON.stringify(LAYOUT)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet])

  const onBuildingClick = (type) => {
    setIsModalVisible(true)
    setBuildingTypeClicked(type)
  }

  const Map = () => {
    useEffect(() => {
      ReactTooltip.rebuild()
    }, [])

    console.log('Rendering map', new Date())
    let index = 0

    const map = globalMap.map((row, y) => row.map((column, x) => {
      index += 1
      const posX = x * plotWidth + ((y % 2) * plotWidth) / 2
      const posY = (y * plotHeight) / 2 - plotOffset * y
      const style = {
        width: `${plotWidth / 16}rem`,
        top: `${posY / 16}rem`,
        left: `${posX / 16}rem`,
      }
      const type = PLOTS[column]?.split('-')
      if (!type) { return <></> }

      return (
        <>
          <img
            data-for="building"
            src={`./images/land/${PLOTS[column]}`}
            className={`${column >= 1 && column <= 13 ? 'heptagon' : 'field'} ${getBuildingClass(PLOTS[column])}`}
            style={style}
            alt={PLOTS[column]}
            data-tip={type[1] === 'farm' ? type[0] : undefined}
            onClick={type[1] === 'farm' ? () => { onBuildingClick(`${type[0]}-${type[1]}`) } : undefined}
            key={`plot_${index}`}
          />
        </>
      )
    }))
    return <>{map}</>
  }

  return (
    <>
      <ReactTooltip
        place="top"
        textColor="white"
        id="building"
        backgroundColor="white"
        className="tooltip-container"
        globalEventOff="click"
        getContent={(dataTip) => (
          <img src={`../images/icons/${dataTip}.png`} alt="resource icon" />
        )}
      />
      {!areBuildingsFetched && (
        <div className="disable-map">
          <p>
            <img src="../images/loading.svg" width="32" alt="" />
            <span>{translate('loadingConstructions')}</span>
          </p>
        </div>
      )}
      <div id="map">
        <Map />
      </div>
      <div id="mobile-map">
        {/* get list of all user buildings and show in same way as buildings for farm */}
        {['wood', 'stone', 'iron', 'wheat'].map((buildingType) => {
          const hasBuilding = userBuildings.find((userBuilding) => userBuilding.resource === buildingType)

          return (
            <div
              key={buildingType}
              className="building-container"
              onClick={() => onBuildingClick(`${buildingType}-farm-${hasBuilding ? '2' : '1'}`)}
            >
              <span className="building-level-text">
                {translate('level')}
                {' '}
                {hasBuilding ? '1' : '0'}
              </span>
              <div className="image-container">
                <img src={`./images/land/${buildingType}-farm-${hasBuilding ? '2' : '1'}.png`} alt="building type" />
              </div>
              <div className="building-details-container">
                <div className="resourse-image-container">
                  <img src={`../images/icons/${buildingType}.png`} alt="resource type" />
                </div>
                <div className="building-text-container">
                  <span>{getBuildingName(buildingType as ResourceTypes)}</span>
                  <span>
                    <span className="building-rate-text">{`+ ${hasBuilding ? 54 : 36}`}</span>
                    <span className="building-rate-type">{` ${translate(buildingType)}/${translate('hour')}`}</span>
                  </span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <ModalRoot
        isOpen={isModalVisible}
        handleClose={() => {
          setIsModalVisible(false)
          setBuildingTypeClicked('')
        }}
        buildingType={buildingTypeClicked?.split('-')[0]}
      />
    </>
  )
}

export default HomePage
