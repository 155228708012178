import {
  Transaction, PublicKey, sendAndConfirmRawTransaction, LAMPORTS_PER_SOL,
} from '@solana/web3.js'
import base58 from 'bs58'
// @ts-ignore:
import { TOKEN_PROGRAM_ID, createTransferCheckedInstruction } from '@solana/spl-token'
import { toast } from 'react-toastify'

import { ResourceType } from '../types'
import { IWallet } from '../hooks/useMultichainWallet'
import CONFIG from '../config.json'
import { getKeyByValue, getBuildingCost } from './helpers'
import connection from './connection'
import fetchWorkersAndBuildings from './nfts'
import { translate } from '../localization'

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID: PublicKey = new PublicKey(
  'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL',
)

interface BuildingResponse {
  status: string;
  description: string;
}

const upgradeBuilding = async (
  wallet: IWallet, buildingType, setIsLoadingTx,
): Promise<(
  BuildingResponse | null
  )> => {
  const transaction = new Transaction();
  ['wood', 'stone', 'iron'].map(async (resource) => {
    if (!wallet?.address) {
      return
    }
    const fromTokenAccount = (
      await PublicKey.findProgramAddress(
        [
          // @todo: test is it works?
          new PublicKey(wallet?.address).toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          new PublicKey(getKeyByValue(CONFIG.tokens, resource)).toBuffer(),
        ],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
      )
    )[0]
    const toTokenAccount = (
      await PublicKey.findProgramAddress(
        [
          new PublicKey(CONFIG.characterCreators[0]).toBuffer(),
          TOKEN_PROGRAM_ID.toBuffer(),
          new PublicKey(getKeyByValue(CONFIG.tokens, resource)).toBuffer(),
        ],
        SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
      )
    )[0]
    transaction.add(
      createTransferCheckedInstruction(
        fromTokenAccount,
        new PublicKey(getKeyByValue(CONFIG.tokens, resource)),
        toTokenAccount,
        wallet?.address,
        getBuildingCost(buildingType, 1)[resource] * LAMPORTS_PER_SOL,
        9, // decimals
      ),
    )
  })

  setIsLoadingTx(true)
  const recentBlockhash = await connection.getLatestBlockhash()

  transaction.feePayer = wallet.address as PublicKey
  transaction.recentBlockhash = recentBlockhash.blockhash

  if (!wallet?.signTransaction) return null

  let txId = ''
  try {
    const signedTx = await wallet?.signTransaction(transaction)

    const signature = base58.encode(Buffer.from(signedTx.signature ? signedTx.signature : ''))

    txId = await sendAndConfirmRawTransaction(
      connection,
      signedTx.serialize(),
      {
        signature,
        blockhash: recentBlockhash.blockhash,
        lastValidBlockHeight: recentBlockhash.lastValidBlockHeight,
      },
    )
  } catch (e) {
    setIsLoadingTx(false)
    toast.error(String(e))
    console.log('Building update eror')
    console.log(e)
  }

  const requestHeaders: HeadersInit = new Headers()
  requestHeaders.set('Content-Type', 'application/json')
  const result = await fetch(`${CONFIG.apiURL}/build`, {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify({
      address: wallet.address, building: buildingType, transaction: txId,
    }),
  })
  setIsLoadingTx(false)

  return result.json()
}

const getBuildingByType = async (buildingResource, address): Promise<boolean> => {
  const { buildings } = await fetchWorkersAndBuildings(address)
  return !!buildings.find((building) => building.resource === `${buildingResource}`)
}

const getBuildingName = (type: keyof typeof ResourceType) => {
  if (type === ResourceType.wood) {
    return translate('Timber Camp')
  }
  if (type === ResourceType.stone) {
    return translate('Stone Quarry')
  }
  if (type === ResourceType.iron) {
    return translate('Iron Mine')
  }
  if (type === ResourceType.wheat) {
    return translate('Wheat Farm')
  }
  return ''
}

export {
  getBuildingName,
  upgradeBuilding,
  getBuildingByType,
}
