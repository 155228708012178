/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Modal } from '@mui/material'

import { Quest } from '../../types'
import { GradientButton, ModalHeaderContainer, ProgressBar } from '../ui'
import { ThreeDotsIcon } from '../../icons'
import { formatText } from '../../utils/helpers'
import { translate } from '../../localization'

const rewardImage = {
  wood: '../images/icons/wood.png',
  iron: './images/icons/iron.png',
  stone: './images/icons/stone.png',
  wheat: './images/icons/wheat.png',
  meta: './images/metaversium.png',
  xp: './images/icons/xp.png',
}

const parseReward = (reward): { name: string, imgSrc: string, amount: number } => {
  const amount = reward.split(' ')[0] || 0
  const name = reward.split(' ')[1] || ''
  const imgSrc = rewardImage[name.toLowerCase()] || ''
  return {
    amount,
    name,
    imgSrc,
  }
}

interface QuestRowProps extends Quest {
  onClaim: (quest_id: string) => void;
  onHide: () => void;
}

const QuestRow = ({
  onClaim, quest_id, image, rewards, title, description,
  is_published, is_claimed, can_be_claimed, progress, onHide,
}: QuestRowProps) => {
  const [isClaiming, setIsClaimging] = useState(false)
  const [isDetailsOpen, setIsDetailsOpen] = useState(false)

  return (
    <>
      <tr
        className={`quest-row ${!is_published ? 'coming-soon' : ''}`}
        onClick={(e) => {
          e.stopPropagation()
          if (!is_published) return
          setIsDetailsOpen(true)
        }}
      >
        <td>
          <div className="quest-title-container">
            <span>{formatText(title, 40)}</span>
            {is_published && !is_claimed && (
              <ProgressBar variant="determinate" value={progress} />
            )}
            <br />
          </div>
        </td>
        <td>
          <div className="quest-rewards-container">
            {rewards.map((reward, index) => (
              <div className="quest-details-reward" key={parseReward(reward).name + index}>
                {parseReward(reward).imgSrc && (
                <img src={parseReward(reward).imgSrc} alt={parseReward(reward).name} />
                )}
                <span className="quest-details-reward-text">{parseReward(reward).amount}</span>
              </div>
            ))}
          </div>
        </td>
        <td className={is_claimed ? 'completed' : ''}>
          <div className="quest-actions-container">
            {is_claimed && (
              <span className="center-text">
                {`🎉 ${translate('completed')}`}
              </span>
            )}
            {!is_claimed && (
              <>
                <div className="quest-mobile-button">
                  <ThreeDotsIcon />
                </div>
                <div className="quest-buttons">
                  <GradientButton
                    onClick={() => {
                      if (!is_published) return
                      setIsDetailsOpen(true)
                    }}
                    text={translate('view')}
                  />
                  <GradientButton
                    onClick={async (e) => {
                      e?.stopPropagation()
                      setIsClaimging(true)
                      console.log('Claiming reward')
                      await onClaim(quest_id)
                      setIsClaimging(false)
                      onHide()
                    }}
                    isDisabled={!can_be_claimed || isClaiming}
                    isLoading={isClaiming}
                    text={translate('claim')}
                    color="green"
                    style={{ boxShadow: 'none' }}
                  />
                </div>
              </>
            )}
          </div>
        </td>
      </tr>
      <Modal
        open={isDetailsOpen}
        onClose={() => setIsDetailsOpen(false)}
        disableEnforceFocus={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-root-quest-details"
      >
        <>
          <ModalHeaderContainer title={translate('quest')} onClose={() => setIsDetailsOpen(false)} />
          <div className="modal-container quest-details-container">
            <div className="quest-details-modal-body">
              <div className="quest-details-image-container">
                <img className="quest-details-image" src={image} alt="nft" />
              </div>
              <div className="quest-details-info-container">
                <span className="quest-details-name">{title}</span>
                <span className="quest-details-desc">{description}</span>
                <div
                  className="quest-details-rewards-container"
                  style={{
                    justifyContent: rewards.length > 3 ? 'space-around' : 'space-between',
                    flexWrap: rewards.length > 3 ? 'wrap' : 'nowrap',
                  }}
                >
                  {rewards.map((reward, index) => (
                    <div className="quest-details-reward" key={parseReward(reward).name + index} style={{ width: rewards.length > 3 ? '40%' : '31%' }}>
                      {parseReward(reward).imgSrc && (
                      <img src={parseReward(reward).imgSrc} alt={parseReward(reward).name} />
                      )}
                      <span className="quest-details-reward-text">
                        {`+ ${parseReward(reward).amount}`}
                      </span>
                    </div>
                  ))}
                </div>
                {!is_claimed && (
                  <GradientButton
                    text={translate('claim')}
                    style={{
                      boxShadow: 'none', margin: '0 auto', marginTop: '1.5rem',
                    }}
                    onClick={async (e) => {
                      e?.stopPropagation()
                      setIsClaimging(true)
                      console.log('Claiming reward')
                      await onClaim(quest_id)
                      setIsClaimging(false)
                      setIsDetailsOpen(false)
                    }}
                    isDisabled={!can_be_claimed || isClaiming}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

export default QuestRow
