import React, { useState } from 'react'

// import LeftNavigation from './LeftNavigation'
import TopNavigation from './TopNavigation'
import ModalQuests from './ModalQuests'
import ModalWorkers from './ModalWorkers'
import ModalLeaderboard from './ModalLeaderboard'
import ModalMobileBalances from './ModalMobileBalances'
import ModalProfile from './ModalProfile'

export const ModalStatusType = {
  workers: 'workers',
  questsHarvesting: 'questsHarvesting',
  questsSocial: 'questsSocial',
  achievements: 'achievements',
  leaderboard: 'leaderboard',
  balances: 'balances',
  profile: 'profile',
} as const

export type ModalStatusTypes =
  typeof ModalStatusType[keyof typeof ModalStatusType]

const Navigation = () => {
  const [modalStatus, setModalStatus] = useState<ModalStatusTypes | null>(null)

  return (
    <>
      {/* <LeftNavigation handleModal={setIsModalJobsVisible} /> */}
      <TopNavigation handleModal={setModalStatus} />
      <ModalWorkers
        isOpen={modalStatus === 'workers'}
        onHide={() => setModalStatus(null)}
      />
      <ModalQuests
        isOpen={modalStatus ? modalStatus.includes('quests') : false}
        questsType={modalStatus === 'questsHarvesting' ? 'harvesting' : 'social'}
        onHide={() => setModalStatus(null)}
      />
      <ModalLeaderboard
        isOpen={modalStatus === 'leaderboard'}
        onHide={() => setModalStatus(null)}
      />
      <ModalMobileBalances
        isOpen={modalStatus === 'balances'}
        onHide={() => setModalStatus(null)}
      />
      <ModalProfile
        isOpen={modalStatus === 'profile'}
        onHide={() => setModalStatus(null)}
      />
    </>
  )
}

export default Navigation
