import React, { useEffect, useState, useMemo } from 'react'

import { useMultichainWallet, useFetchAptosTokensBalances } from '../hooks'
import { TokenBalances } from '../types'

export interface IAptosTokenBalancesContext {
  balances: TokenBalances | null,
  setBalances: (balances: TokenBalances | {}) => void;
  updateBalances: () => void;
}

const defaultTokenBalances = {
  balances: {
    wood: 0,
    iron: 0,
    stone: 0,
    wheat: 0,
    meta: 0,
  },
  setBalances: () => {},
  updateBalances: () => {},
}

export const AptosTokenBalancesContext = React.createContext<IAptosTokenBalancesContext>(
  defaultTokenBalances,
)

export function AptosTokenBalancesProvider({ children }) {
  const { address, connected } = useMultichainWallet()
  const [tokenBalances, setTokenBalances] = useState<TokenBalances | null>(
    defaultTokenBalances.balances,
  )

  // @todo: add type later
  const { data, refetch } = useFetchAptosTokensBalances()

  const updateBalances = () => {
    refetch()
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const updatedBalances = {}
      data.forEach((tokenBalance) => {
        updatedBalances[tokenBalance.name.toLowerCase()] = tokenBalance.amount
      })
      setTokenBalances(updatedBalances as TokenBalances)
    }
  }, [data])

  useEffect(() => {
    if (!address && !connected) {
      setTokenBalances(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  const balancesData = useMemo<IAptosTokenBalancesContext>(
    () => ({
      balances: tokenBalances,
      setBalances: (newBalances) => setTokenBalances(newBalances as TokenBalances),
      updateBalances,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenBalances],
  )

  return (
    <AptosTokenBalancesContext.Provider value={balancesData}>
      {children}
    </AptosTokenBalancesContext.Provider>
  )
}
