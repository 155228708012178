import { useQuery } from 'react-query'
import { getRewardMap } from '@cardinal/staking'
import BN from 'bn.js'
import useRewardDistributorData from './useRewardDistributor'
import useStakedToken from './useStakedToken'
import { useRewardDistributorTokenAccount } from './useRewardDistributorTokenAccount'
import useRewardEntries from './useRewardEntries'
import useSolanaClock from './useSolanaClock'
import getMintDecimalAmountFromNatural from '../utils/units'

type RewardType = {
  [stakeEntryId: string]: string
}

const useRewards = (stakePoolId: string) => {
  const UTCNow = useSolanaClock()
  const { data: rewardDistributorData } = useRewardDistributorData(stakePoolId)
  const { data: rewardDistributorTokenAcc } = useRewardDistributorTokenAccount(stakePoolId)
  const { data: stakeEntries } = useStakedToken()
  const { data: rewardEntries } = useRewardEntries(stakePoolId)

  return useQuery<RewardType>(
    [
      'useRewards',
      stakePoolId,
      // rewardDistributorData,
      // rewardEntries,
      // stakeEntries,
      UTCNow,
    ],
    () => {
      if (!stakeEntries || !rewardEntries || !rewardDistributorData || !rewardDistributorTokenAcc) {
        return {}
      }

      let rewardMap
      try {
        rewardMap = getRewardMap(
          stakeEntries,
          rewardEntries,
          rewardDistributorData,
          new BN(rewardDistributorTokenAcc!.amount),
          UTCNow,
        )
      } catch (e) {
        console.error(e)
      }
      const pending = {}
      if (rewardMap && stakeEntries) {
        for (let i = 0; i < stakeEntries.length; i += 1) {
          const stakeEntry = stakeEntries[i]
          const pubkey = stakeEntry.pubkey.toString()
          if (rewardMap.rewardMap[pubkey]) {
            pending[stakeEntry.parsed.originalMint.toString()] = getMintDecimalAmountFromNatural(
              rewardMap.rewardMap[pubkey].claimableRewards,
            )
          }
        }
      }
      return pending
    },
    {
      keepPreviousData: false, // true,
      enabled: true,
    },
  )
}

export default useRewards
