import React from 'react'
import { toast } from 'react-toastify'

import { Chains } from '../types'
import {
  useAptosAirdrop, useTokenBalances, useAptosTokensBalances, useMultichainWallet,
} from '../hooks'
import { translate } from '../localization'

const BalancesSheet = () => {
  const { balances } = useTokenBalances()
  const { balances: aptosBalances, updateBalances } = useAptosTokensBalances()
  const { address, connected, connectedChain } = useMultichainWallet()

  const { requestAptosAirdrop, isLoading } = useAptosAirdrop()

  const onRequestAirdrop = async () => {
    const result = await requestAptosAirdrop(String(address))

    if (result.success) {
      toast.success(translate('airdropReceived'))

      updateBalances()
    } else {
      toast.error(translate(String(result.reason)))
    }
  }

  return (
    <div className="balances-sheet-container">
      <div className="balance-container">
        <img src="../images/icons/wood.png" alt="wood" />
        <span className="balance-text">
          {balances?.wood || aptosBalances?.wood || 0}

        </span>
      </div>
      <div className="balance-container">
        <img src="../images/icons/stone.png" alt="stone" />
        <span className="balance-text">
          {balances?.stone || aptosBalances?.stone || 0}

        </span>
      </div>
      <div className="balance-container">
        <img src="../images/icons/iron.png" alt="iron" />
        <span className="balance-text">
          {balances?.iron || aptosBalances?.iron || 0}

        </span>
      </div>
      <div className="balance-container">
        <img src="../images/icons/wheat.png" alt="wheat" />
        <span className="balance-text">
          {balances?.wheat || aptosBalances?.wheat || 0}

        </span>
      </div>
      {connected && connectedChain === Chains.aptos ? (
        <div className="balance-container">
          <button
            disabled={isLoading}
            type="button"
            className={`airdrop-button ${isLoading && 'disabled'}`}
            onClick={onRequestAirdrop}
          >
            {isLoading && <img src="../images/loading.svg" alt="loading spinner" />}
            <span>AIRDROP WOOD</span>
          </button>
        </div>
      ) : (
        <div className="balance-container">
          <img src="../images/metaversium.png" className="meta-resource" alt="$META" />
          <span className="balance-text">{balances?.meta || 0}</span>
        </div>
      )}
    </div>
  )
}

export default BalancesSheet
