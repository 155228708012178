import React from 'react'

interface TextInputProps {
  value: string;
  onChange: (newValue: any) => void;
  placeholder?: string;
}

const TextInput = ({ value, onChange, placeholder }: TextInputProps) => (
  <div className="text-input-container">
    <input value={value} onChange={onChange} placeholder={placeholder} />
  </div>
)

export default TextInput
