import { useEffect, useState, useMemo } from 'react'
import connection from 'utils/connection'

const getSolanaClock = async (): Promise<number | null> => {
  const epochInfo = await connection.getEpochInfo()
  const blockTimeInEpoch = await connection.getBlockTime(epochInfo.absoluteSlot)
  return blockTimeInEpoch
}

const useSolanaClock = () => {
  const [UTCNow, setUTCNow] = useState(Math.floor(Date.now() / 1000))

  useEffect(() => {
    const interval = setInterval(
      function fetchInterval(): () => void {
        setUTCNow((old) => old + 1)
        return fetchInterval
      }, 1000,
    )
    return () => clearInterval(interval)
  }, [])

  useMemo(async () => {
    try {
      const solanaClock = await getSolanaClock()
      if (solanaClock) {
        setUTCNow(solanaClock)
      }
    } catch (e) {
      console.error(e)
    }
  }, [])

  return UTCNow
}

export default useSolanaClock
