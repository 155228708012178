/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'

import { ModalStatusTypes } from '../Navigations'
import { Chains } from '../../types'
import { useMultichainWallet } from '../../hooks'
import { DownIcon, UpIcon } from '../../icons'
import { translate } from '../../localization'

const { REACT_APP_MULTICHAIN } = process.env

interface MobileMenuProps {
  isMobMenuVis: boolean,
  setSelectWalletVis: (isSelected: boolean) => void,
  handleModal: (modalType: ModalStatusTypes) => void;
}

const MobileMenu = ({
  isMobMenuVis, handleModal, setSelectWalletVis,
}: MobileMenuProps) => {
  const [isMobQuestsVis, setMobQuestsVis] = useState(false)

  const {
    wallet, disconnect, connectedChain,
  } = useMultichainWallet()

  return (isMobMenuVis ? (
    <div className="mobMenuItems">
      <div onClick={() => handleModal('balances')}>
        <p className="menuText">{translate('balances')}</p>
      </div>
      <div className="divider" />
      <div onClick={() => handleModal('leaderboard')}>
        <p className="menuText">{translate('leaderboard')}</p>
      </div>
      <div className="divider" />
      <div onClick={() => handleModal('workers')}>
        <p className="menuText">{translate('workers')}</p>
      </div>
      <div className="divider" />
      <div onClick={() => setMobQuestsVis(!isMobQuestsVis)}>
        <div className="questsMenuItem">
          <p className="menuText">{translate('quests')}</p>
          {isMobQuestsVis ? <UpIcon /> : <DownIcon />}
        </div>
      </div>
      {isMobQuestsVis && (
        <>
          <div
            onClick={() => {
              setMobQuestsVis(false)
              handleModal('questsHarvesting')
            }}
          >
            <p className="menuText">{translate('harvesting')}</p>
          </div>
          <div
            onClick={() => {
              setMobQuestsVis(false)
              handleModal('questsSocial')
            }}
          >
            <p className="menuText">{translate('social')}</p>
          </div>
        </>
      )}
      <div className="divider" />
      {wallet && (
        <>
          <div onClick={() => handleModal('profile')}>
            <p className="menuText">{translate('profile')}</p>
          </div>
          <div className="divider" />
        </>
      )}
      <div className="connect-button">
        {wallet ? (
          connectedChain === Chains.solana ? <WalletDisconnectButton /> : (
            <button type="button" className="disconnect-chain-button" onClick={() => disconnect()}>
              <img src={'icon' in wallet ? wallet?.icon : ''} alt="wallet" width="24" />
              <span>{translate('disconnect')}</span>
            </button>
          )
        ) : (
          REACT_APP_MULTICHAIN ? (
            <div className="nav-item">
              <button type="button" className="select-chain-button" onClick={() => setSelectWalletVis(true)}>
                <span>{translate('connect')}</span>
              </button>
            </div>
          ) : <WalletMultiButton />
        )}
      </div>
    </div>
  ) : <></>)
}

export default MobileMenu
