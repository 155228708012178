import { gql, useQuery as useGraphqlQuery } from '@apollo/client'

import useMultichainWallet from './useMultichainWallet'
import CONFIG from '../config.json'

const ACCOUNT_TOKENS_BALANCES_QUERY = gql`
  query AccountTokensBalances($owner_address: String, $creator_address: String, $collection_name: String) {
    current_token_ownerships(
      where: {owner_address: {_eq: $owner_address}, creator_address: {_eq: $creator_address}, collection_name: {_eq: $collection_name}}
    ) {
      amount
      name
    }
  }
`

const useFetchAptosTokensBalances = () => {
  const { address, connected } = useMultichainWallet()

  const {
    loading, error, data, refetch,
  } = useGraphqlQuery(ACCOUNT_TOKENS_BALANCES_QUERY, {
    variables: {
      owner_address: `0x${String(address).substring(2).padStart(64, '0')}`,
      creator_address: CONFIG.aptosTokensCreator,
      collection_name: CONFIG.aptosCollectionName,
    },
    skip: !connected,
  })

  if (loading || error || !data) {
    return { data: [], refetch, loading }
  }

  return { data: data?.current_token_ownerships, refetch, loading }
}

export default useFetchAptosTokensBalances
