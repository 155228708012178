import { useQuery } from 'react-query'

import { LeaderboardRow, ChainOptions } from 'types'
import CONFIG from '../config.json'

interface LeaderboardResponse {
  status: string;
  leaderboard: LeaderboardRow[];
}

const useLeaderboard = (selectedChain: ChainOptions) => {
  const { isLoading, error, data } = useQuery<LeaderboardResponse>(
    [`leaderboard${selectedChain}`],
    () => fetch(`${CONFIG.apiURL}/leaderboard?chain=${selectedChain}`).then((res) => res.json()),
    { cacheTime: 0, staleTime: 0 },
  )
  return {
    isLoading,
    error,
    data,
  }
}

export default useLeaderboard
