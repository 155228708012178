import React from 'react'
import Confetti from 'react-confetti'

import { useConfetti } from '../../hooks'

const ConfettiContainer = () => {
  const { isRun, setIsRun } = useConfetti()

  return (
    <Confetti
      run={isRun}
      recycle={false}
      numberOfPieces={1000}
      gravity={0.15}
      width={window.innerWidth}
      height={window.innerHeight}
      onConfettiComplete={(confetti) => {
        confetti?.reset()
        setIsRun(false)
      }}
    />
  )
}

export default ConfettiContainer
