import React, { useEffect, useState } from 'react'

import { ModalHeaderContainer, SwitchTabs } from '../ui'
import { translate } from '../../localization'
import { useLeaderboard } from '../../hooks'
import { formatText } from '../../utils/helpers'
import {
  Top1Icon, Top2Icon, Top3Icon, AchievementsIcon,
} from '../../icons'
import { LeaderboardRow, ChainOptions, Chains } from '../../types'

const { REACT_APP_MULTICHAIN } = process.env

interface ModalLeaderboardBodyProps {
  onHide: () => void;
}

const rewardImage = {
  wood: '../images/icons/wood.png',
  iron: './images/icons/iron.png',
  stone: './images/icons/stone.png',
  wheat: './images/icons/wheat.png',
  meta: './images/metaversium.png',
  xp: './images/icons/xp.png',
}

const ModalLeaderboardBody = ({ onHide }: ModalLeaderboardBodyProps) => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardRow[]>([])
  const [activeOption, setActiveOption] = useState<ChainOptions>(
    REACT_APP_MULTICHAIN ? Chains.all : Chains.solana,
  )

  const { isLoading, data } = useLeaderboard(activeOption)

  useEffect(() => {
    setLeaderboard(data?.leaderboard || [])
  }, [data?.leaderboard])

  return (
    <>
      <ModalHeaderContainer
        title={translate('leaderboard')}
        onClose={onHide}
      />

      <div className="modal-container">
        <div className="modal-body-container modal-body-workers modal-body-leaderboard">
          {REACT_APP_MULTICHAIN ? (
            <SwitchTabs
              active={activeOption}
              options={Object.values(Chains)}
              toggleSwitch={setActiveOption}
            />
          ) : null}
          {isLoading ? (
            <h3 className="loading">
              <img src="../images/loading.svg" width="32" alt="loading" />
              <br />
              {translate('loadingLeaderboard')}
            </h3>
          ) : (
            <>
              <div className="leaderboard-table-container">
                <table className="leaderboard-table" cellSpacing="0" cellPadding="0">
                  <tbody>
                    {leaderboard.map((scoreRow, index) => (
                      <tr className="score-row" key={scoreRow.address}>
                        <td>
                          {index === 0 && <Top1Icon />}
                          {index === 1 && <Top2Icon />}
                          {index === 2 && <Top3Icon />}
                          {index > 2 && index + 1}
                        </td>
                        <td>{formatText(scoreRow.address, 15)}</td>
                        <td className="score-row-resourses">
                          <div>
                            <div className="resource">
                              <img src={rewardImage.iron} alt="iron" width="32" />
                              {scoreRow.iron}
                            </div>
                            <div className="resource">
                              <img src={rewardImage.stone} alt="stone" width="32" />
                              {scoreRow.stone}
                            </div>
                          </div>
                          <div>
                            <div className="resource">
                              <img src={rewardImage.wood} alt="wood" width="32" />
                              {scoreRow.wood}
                            </div>
                            <div className="resource">
                              <img src={rewardImage.wheat} alt="wheat" width="32" />
                              {scoreRow.wheat}
                            </div>
                          </div>
                        </td>
                        <td className="score-row-workers">
                          <div className="number-of-workers">
                            <img src="../images/icons/worker.png" alt="nft" width="30" />
                            <span>
                              x
                              {scoreRow.nft_characters}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="total-score">
                            <span>{scoreRow.score}</span>
                            <AchievementsIcon />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ModalLeaderboardBody
