import { useState, useEffect } from 'react'
import { useWallet as useSolanaWallet, Wallet } from '@solana/wallet-adapter-react'
import { useWallet as useAptosWallet, WalletName } from '@aptos-labs/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { type SignerWalletAdapterProps } from '@solana/wallet-adapter-base'

import { ChainOptions, Chains } from '../types'

declare type WalletInfo = {
  name: WalletName;
  icon: string;
  url: string;
}

export interface IWallet {
  address: PublicKey | string;
  connected: boolean;
  connect: (chainType: ChainOptions, walletName?: any) => void | Promise<void>;
  disconnect: () => void | Promise<void>;
  wallets: Array<any>;
  connectedChain: ChainOptions
  // wallet - for solana and WalletInfo for aptos
  wallet: Wallet | WalletInfo | null;
  // only in solana
  select?: (walletName: WalletName) => void;
  signTransaction?: SignerWalletAdapterProps['signTransaction'] | undefined;

}

const defaultWallet = {
  address: '',
  connected: false,
  connect: () => {},
  disconnect: () => {},
  wallets: [],
  wallet: null,
  select: () => {},
  connectedChain: Chains.solana,
}

// wallet.publicKey (solana) -> should address
const useMultichainWallet = () => {
  const [walletSelected, setWalletSelected] = useState<IWallet>(defaultWallet)

  const {
    wallet: solanaWallet,
    wallets: solanaWallets,
    connect: solanaConnect,
    select,
    disconnect: solanaDisconnect,
    signTransaction: signSolanaTransaction,
  } = useSolanaWallet()
  const {
    account,
    wallet: aptosWallet,
    wallets: aptosWallets,
    connect: aptosConnect,
    connected: aptosConnected,
    disconnect: aptosDisconnect,
  } = useAptosWallet()

  const onConnect = (chainType: ChainOptions, walletName) => {
    if (chainType === Chains.aptos) {
      aptosConnect(walletName)
    } else {
      solanaConnect()
    }
  }

  useEffect(() => {
    const aptosAllWallets = aptosWallets.map(
      (aptosExtension: any) => ({ ...aptosExtension, chain: Chains.aptos }),
    )
    const solanaAllWallets = solanaWallets.map(
      (solanaExtension: any) => {
        const { adapter } = solanaExtension
        // temp fix, need to investigate later
        return {
          name: adapter.name,
          icon: adapter.icon,
          chain: Chains.solana,
        }
      },
    )
    const allWallets = [...aptosAllWallets, ...solanaAllWallets]

    // without a connected wallet
    if (!aptosConnected && !solanaWallet) {
      setWalletSelected({
        wallets: allWallets,
        connect: onConnect,
        select,
      } as any)
      return
    }
    if (aptosConnected && account) {
      setWalletSelected({
        address: account.address,
        connected: aptosConnected,
        connect: onConnect,
        disconnect: aptosDisconnect,
        wallets: allWallets,
        wallet: aptosWallet,
        connectedChain: Chains.aptos,
      })
    } else if (solanaWallet && solanaWallet.adapter.publicKey && !solanaWallet.adapter.connecting) {
      setWalletSelected({
        address: solanaWallet.adapter.publicKey,
        connected: solanaWallet.adapter.connected,
        connect: onConnect,
        disconnect: solanaDisconnect,
        wallets: allWallets,
        wallet: solanaWallet,
        select,
        connectedChain: Chains.solana,
        signTransaction: signSolanaTransaction,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aptosConnected, account, solanaWallet?.adapter.publicKey, aptosConnect, solanaConnect,
    aptosWallets, solanaWallets, select, aptosDisconnect, aptosWallet,
    solanaDisconnect, solanaWallet?.adapter.connecting])

  return walletSelected
}

export default useMultichainWallet
