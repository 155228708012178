import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useWallet } from '@solana/wallet-adapter-react'

import fetchWorkersAndBuildings from '../../utils/nfts'
import { DoneIcon } from '../../icons'
import { handleStake, handleUnstake } from '../../utils/staking'
import { Worker } from '../../types'
import { getKeyByValue } from '../../utils/helpers'
import { translate } from '../../localization'
import CONFIG from '../../config.json'
import {
  useRewards, useTokenBalances, useStakedToken, useMultichainWallet,
} from '../../hooks'
import {
  ModalHeaderContainer,
  GradientButton,
  SwitchTabs,
  WorkerImage,
} from '../ui'

interface ModalWorkersBodyProps {
  onHide: () => void;
  type: 'farm' | 'main';
  farmType: string | undefined;
  // we dont need it in finish workers
  buildingLevel?: number;
}

type RewardType = {
  [stakeEntryId: string]: string
}

interface WorkerProps extends Worker {
  onSelected: boolean;
  onSelectWorker: (mint: string) => void;
  pendingReward: {
    wood: RewardType | undefined;
    stone: RewardType | undefined;
    iron: RewardType | undefined;
    wheat: RewardType | undefined;
  }
}

const Options = {
  all: 'all',
  busy: 'busy',
  available: 'available',
} as const

type SwitchOptions = typeof Options[keyof typeof Options]

const WorkerComponent = ({
  mint, name, status, pendingReward, onSelectWorker, onSelected, image,
}: WorkerProps) => (
  <div className={onSelected ? 'worker-container active' : 'worker-container'} onClick={() => onSelectWorker(mint)}>
    <div className={onSelected ? 'checkbox active' : 'checkbox'}>
      {onSelected && <DoneIcon />}
    </div>
    <WorkerImage src={image} status={status} />
    <div className="worker-info-container">
      <p className="worker-info-name">{name}</p>
      <div>
        <div className="worker-info-status">
          <div className={status ? 'worker-status-busy' : 'worker-status-free'} />
          <p>{status && pendingReward && pendingReward[status] ? `${pendingReward[status][mint]} ${status.split('_')[0].toUpperCase()}` : translate('available')}</p>
        </div>
      </div>
    </div>
  </div>
)

const ModalWorkersBody = ({
  buildingLevel = 0, onHide, type, farmType,
}: ModalWorkersBodyProps) => {
  const [selectedWorkers, setSelectedWorkers] = useState<Array<string>>([])
  const [lockedMints, setLockedMints] = useState({})
  const [isLoadingNfts, setIsLoadingNfts] = useState(false)
  const [isLoadingTx, setIsLoadingTx] = useState(false)
  const [workers, setWorkers] = useState<Worker[]>([])
  const [sortedWorkers, setSortedWorkers] = useState<Worker[]>([])
  const [activeOption, setActiveOption] = useState<SwitchOptions>(Options.all)

  const { wallet } = useWallet()
  const { address } = useMultichainWallet()
  const stakedTokens = useStakedToken()
  const { updateBalances } = useTokenBalances()

  /* TODO: check performance impact */
  /* TODO: monitor number of RPC requests */
  const rewards = {
    wood: useRewards(CONFIG.pools.wood).data,
    stone: useRewards(CONFIG.pools.stone).data,
    iron: useRewards(CONFIG.pools.iron).data,
    wheat: useRewards(CONFIG.pools.wheat).data,
    wood_1: useRewards(CONFIG.pools.wood_1).data,
    stone_1: useRewards(CONFIG.pools.stone_1).data,
    iron_1: useRewards(CONFIG.pools.iron_1).data,
  }

  const onSelectWorker = (mint: string) => {
    const isSelected = Boolean(selectedWorkers.find((selected) => selected === mint))
    if (isSelected) {
      setSelectedWorkers(selectedWorkers.filter((selected) => selected !== mint))
      return
    }
    setSelectedWorkers([...selectedWorkers, mint])
  }

  useEffect(() => {
    switch (activeOption) {
      case Options.all:
        setSortedWorkers([...workers])
        break
      case Options.available:
        setSortedWorkers([...workers.filter((worker) => !lockedMints[worker.mint])])
        break
      case Options.busy:
        setSortedWorkers([
          ...workers.filter(
            (worker) => (
              lockedMints[worker.mint]),
          ),
        ])
        break
      default:
        setSortedWorkers([...workers])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOption, workers])

  useEffect(() => {
    async function fetchNFTs() {
      if (stakedTokens.data) {
        const busyWorkers = {}
        for (let i = 0; i < stakedTokens.data.length; i += 1) {
          const entry = stakedTokens.data[i].parsed
          busyWorkers[entry?.originalMint.toBase58()] = entry?.pool.toBase58()
        }
        setLockedMints(busyWorkers)
      }
      setWorkers([])
      if (address) {
        setIsLoadingNfts(true)
        // console.log('[Modal Buildings] Loading workers...')
        const response = await fetchWorkersAndBuildings(address)
        // console.log('[Modal Buildings] Workers', response)
        setIsLoadingNfts(false)
        if (response && response.workers) {
          setWorkers(response.workers)
        }
      }
    }
    fetchNFTs()
  }, [address, stakedTokens.data])

  return (
    <>
      <ModalHeaderContainer
        title={translate('workers')}
        onClose={() => {
          setSelectedWorkers([])
          onHide()
        }}
      />
      <div className="modal-container">
        <div className="modal-body-container modal-body-workers">
          {type === 'main' && (
            <SwitchTabs
              active={activeOption}
              options={Object.values(Options)}
              toggleSwitch={setActiveOption}
            />
          )}
          <div className="workers-container">
            {isLoadingNfts && (
              <h3 className="loading">
                <img src="../images/loading.svg" width="32" alt="" />
                <br />
                {translate('loadingWorkers')}
              </h3>
            )}
            {address && !isLoadingNfts && !workers.length && (
              <h3 className="loading">{translate('noWorkers')}</h3>
            )}
            {!address && !isLoadingNfts && <h3 className="loading">{translate('walletNotConnected')}</h3>}
            {sortedWorkers.length > 0 && (
              sortedWorkers.map((worker) => (
                <WorkerComponent
                  {...worker}
                  key={worker.mint}
                  status={lockedMints[worker.mint] ? getKeyByValue(CONFIG.pools, lockedMints[worker.mint]) : ''}
                  pendingReward={
                  rewards || undefined
                }
                  onSelectWorker={onSelectWorker}
                  onSelected={
                  Boolean(selectedWorkers.find((selected) => selected === worker.mint))
                }
                />
              ))
            )}
          </div>
        </div>
        <div className="modal-footer-container">
          {address && (
          <>
            {type === 'farm' ? (
              <GradientButton
                text={`${translate('farm')} (${selectedWorkers.length})`}
                style={{ marginTop: '0.8rem', height: '3.5rem' }}
                onClick={async () => {
                  setIsLoadingTx(true)
                  if (farmType) {
                    const poolName = buildingLevel ? `${farmType}_${buildingLevel}` : farmType
                    const result = await handleStake(
                      CONFIG.pools[poolName], selectedWorkers, wallet,
                    )
                    const stakedTxsCount = result.filter((item) => item !== null).length
                    if (stakedTxsCount) {
                      stakedTokens.refetch()
                    }
                    console.log('Result is: ', result)
                    if (result && stakedTxsCount) {
                      toast.success(translate('workerStartFarming'))
                    } else {
                      toast.error('No workers are farming')
                    }
                  }

                  setIsLoadingTx(false)
                  setSelectedWorkers([])
                }}
                isDisabled={!selectedWorkers.length || isLoadingTx}
                isLoading={isLoadingTx}
              />
            ) : (
              <GradientButton
                text={`${translate('finish')} (${selectedWorkers.length})`}
                style={{ marginTop: '0.8rem', height: '3.5rem' }}
                onClick={async () => {
                  setIsLoadingTx(true)
                  const result = await handleUnstake(lockedMints, selectedWorkers, wallet)
                  if (result.filter((item) => item !== null).length) {
                    stakedTokens.refetch()
                  }
                  updateBalances()
                  setIsLoadingTx(false)
                  setSelectedWorkers([])
                }}
                isDisabled={!selectedWorkers.length || isLoadingTx}
                isLoading={isLoadingTx}
              />
            )}
          </>
          )}
        </div>
      </div>
    </>
  )
}

export default ModalWorkersBody
