/* eslint-disable no-param-reassign */
import axios from 'axios'

import { ChainOptions } from 'types'
import CONFIG from '../config.json'

type ReportingData = {
  address: string;
  event: string;
  target?: string;
  transactions?: (string | null)[];
  chain?: ChainOptions;
  client?: {
    os: string;
    browser: string;
    resolution: string;
    language: string;
  }
}

function getOS() {
  let os = 'Unknown'
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
    os = 'Windows OS'
  }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    os = 'MacOS'
  }
  if (window.navigator.appVersion.indexOf('X11') !== -1) {
    os = 'UNIX OS'
  }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) {
    os = 'Linux OS'
  }

  return os
}

function getBrowser() {
  let browser = 'Unknown'
  if (window.navigator.userAgent.indexOf('Chrome') !== -1) {
    browser = 'Google Chrome'
  } else if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
    browser = 'Mozilla Firefox'
  } else if (window.navigator.userAgent.indexOf('MSIE') !== -1) {
    browser = 'Internet Explorer'
  } else if (window.navigator.userAgent.indexOf('Edge') !== -1) {
    browser = 'Edge'
  } else if (window.navigator.userAgent.indexOf('Safari') !== -1) {
    browser = 'Safari'
  } else if (window.navigator.userAgent.indexOf('Opera') !== -1) {
    browser = 'Opera'
  }

  return browser
}

const sendReport = async (data: ReportingData) => {
  data.client = {
    os: getOS(),
    browser: getBrowser(),
    language: window.navigator.language,
    resolution: `${window.innerWidth}x${window.innerHeight}`,
  }
  try {
    await axios.post(`${CONFIG.apiURL}/event`, data)
  } catch (e) {
    console.log('Reporting', e)
  }
}

export default sendReport
