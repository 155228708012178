import { useQuery } from 'react-query'
import { getStakeEntriesForUser } from '@cardinal/staking/dist/cjs/programs/stakePool/accounts'
import { PublicKey } from '@solana/web3.js'

import connection from '../utils/connection'
import { AccountData } from '../types'
import useMultichainWallet from './useMultichainWallet'

const useStakedToken = () => {
  const { address } = useMultichainWallet()
  return useQuery<AccountData[]>(
    ['useStakedToken', address],
    async () => {
      if (!address) {
        return []
      }
      const stakeEntries = (
        await getStakeEntriesForUser(connection, address as PublicKey)
      )
      return stakeEntries.flat() as AccountData[]
    },
  )
}

export default useStakedToken
