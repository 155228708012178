import BN from 'bn.js'
import { stake, unstake, claimRewards } from '@cardinal/staking'
import {
  ReceiptType,
  StakeEntryData,
} from '@cardinal/staking/dist/cjs/programs/stakePool'
import {
  PublicKey, Transaction, AccountInfo, ParsedAccountData, sendAndConfirmRawTransaction,
} from '@solana/web3.js'
import { AccountData } from '@cardinal/common'
import { toast } from 'react-toastify'
import base58 from 'bs58'

import getOwnerAta from './ata'
import connection from './connection'
import sendReport from './reporting'
import CONFIG from '../config.json'
import { getKeyByValue } from './helpers'

export interface Wallet {
  publicKey: PublicKey;
  signTransaction(tx: Transaction): Promise<Transaction>;
  signAllTransactions(txs: Transaction[]): Promise<Transaction[]>;
  adapter: {
    signAllTransactions(txs: Transaction[]): Promise<Transaction[]>;
  }
}

export type TokenListData = {
  chainId: number
  address: string
  symbol: string
  name: string
  decimals: number
  logoURI: string
  tags: string[]
}

export type AllowedTokenData = BaseTokenData & {
  metadata?: any
  stakeEntry?: AccountData<StakeEntryData>
  amountToStake?: string
}

export type BaseTokenData = {
  tokenAccount?: {
    pubkey: PublicKey
    account: AccountInfo<ParsedAccountData>
  }
  tokenListData?: TokenListData
}

const executeAllTransactions = async (
  wallet: Wallet, transactions: Transaction[],
): Promise<(
  string | null
  )[]> => {
  if (transactions.length === 0) return []

  const recentBlockhash = await connection.getLatestBlockhash()

  // eslint-disable-next-line no-restricted-syntax
  for (const tx of transactions) {
    tx.feePayer = wallet.publicKey
    tx.recentBlockhash = recentBlockhash.blockhash
  }

  const signedTxs = await wallet?.adapter.signAllTransactions(transactions)

  const txIds = await Promise.all(
    signedTxs.map(async (tx) => {
      const signature = base58.encode(Buffer.from(tx.signature ? tx.signature : ''))

      try {
        const txid = await sendAndConfirmRawTransaction(
          connection,
          tx.serialize(),
          {
            signature,
            blockhash: recentBlockhash.blockhash,
            lastValidBlockHeight: recentBlockhash.lastValidBlockHeight,
          },
        )
        return txid
      } catch (e) {
        console.log(e)
        toast.error(String(e))
        return null
      }
    }),
  )
  console.log('Successful txs', txIds)
  return txIds
}

const handleStake = async (stakePool, unstakedSelected, wallet): Promise<(string | null)[]> => {
  const receiptType = ReceiptType.Original
  if (!wallet) {
    console.log({ message: 'Wallet not connected', type: 'error' })
    return [null]
  }
  if (!stakePool) {
    console.log({ message: 'Stakepool is not defined', type: 'error' })
    return [null]
  }
  if (unstakedSelected.length <= 0) {
    console.log({ message: 'No workers selected', type: 'error' })
    return [null]
  }
  console.log('Sending to farm in', stakePool)

  const txs: (Transaction | null)[] = await Promise.all(
    unstakedSelected.map(async (mint) => {
      try {
        const amount = new BN(1)
        // eslint-disable-next-line no-param-reassign
        wallet.publicKey = wallet?.adapter.publicKey
        const ata = await getOwnerAta(wallet.publicKey, new PublicKey(mint))
        if (!ata || !ata.address) {
          toast.error('Token account not set')
          throw new Error('Token account not set')
        }
        return await stake(connection, wallet as Wallet, {
          stakePoolId: new PublicKey(stakePool),
          receiptType,
          originalMintId: new PublicKey(
            mint,
          ),
          userOriginalMintTokenAccountId: new PublicKey(
            ata.address,
          ),
          amount,
        })
      } catch (e: any) {
        toast.error(String(e))
        return null
      }
    }),
  )

  let result: (string | null)[] = [null]
  try {
    result = await executeAllTransactions(
      wallet as Wallet,
      txs.filter((tx): tx is Transaction => tx !== null),
    )
    const confirmed = result.find((element) => element != null)
    if (confirmed) {
      sendReport({
        address: wallet?.adapter.publicKey, event: 'stake', target: getKeyByValue(CONFIG.pools, stakePool),
      })
    }
  } catch (e) {
    toast.error(String(e))
    console.log(e)
  }
  return result
}

const handleUnstake = async (stakedWorkers, stakedSelected, wallet) => {
  if (!wallet) {
    console.log({ message: 'Wallet not connected', type: 'error' })
    return [null]
  }
  if (stakedSelected.length <= 0) {
    console.log({ message: 'No workers selected', type: 'error' })
    return [null]
  }

  const txs: (Transaction | null)[] = await Promise.all(
    stakedSelected.map(async (mint) => {
      try {
        // eslint-disable-next-line no-param-reassign
        wallet.publicKey = wallet?.adapter.publicKey
        return await unstake(connection, wallet as Wallet, {
          stakePoolId: new PublicKey(stakedWorkers[mint]),
          originalMintId: new PublicKey(
            mint,
          ),
        })
      } catch (e) {
        toast.error(String(e))
        console.log(e)
        return null
      }
    }),
  )
  let result: (string | null)[] = [null]
  try {
    result = await executeAllTransactions(
      wallet as Wallet,
      txs.filter((tx): tx is Transaction => tx !== null),
    )
    const confirmed = result.find((element) => element != null)
    if (confirmed) {
      sendReport({
        address: wallet?.adapter.publicKey,
        event: 'unstake',
        transactions: result.filter((tx) => tx !== null),
      })
    }
  } catch (e) {
    toast.error(String(e))
    console.log(e)
  }
  return result
}

const handleClaimRewards = async (stakePool, stakedSelected, stakeEntries, wallet) => {
  if (!wallet) {
    console.log({ message: 'Wallet not connected', type: 'error' })
    return [null]
  }
  if (!stakePool) {
    console.log({ message: 'Stakepool is not defined', type: 'error' })
    return [null]
  }

  const txs: (Transaction | null)[] = await Promise.all(
    stakedSelected.map(
      async (mint) => {
        try {
          const stakeEntry = stakeEntries.data.find(
            (entry) => entry.parsed.originalMint.toBase58() === mint,
          )
          if (!stakeEntry) {
            throw new Error('No stake entry for token')
          }
          // eslint-disable-next-line no-param-reassign
          wallet.publicKey = wallet?.adapter.publicKey
          return await claimRewards(connection, wallet as Wallet, {
            stakePoolId: new PublicKey(stakePool),
            stakeEntryId: stakeEntry.pubkey,
          })
        } catch (e) {
          toast.error(`Failed to claim rewards for ${mint}`)
          return [null]
        }
      },
    ),
  )
  let result: (string | null)[] = [null]
  try {
    result = await executeAllTransactions(
      wallet as Wallet,
      txs.filter((tx): tx is Transaction => tx !== null),
    )
    const confirmed = result.find((element) => element != null)
    if (confirmed) {
      sendReport({
        address: wallet?.adapter.publicKey, event: 'claim', target: getKeyByValue(CONFIG.pools, stakePool),
      })
    }
  } catch (e) {
    toast.error(String(e))
    console.log(e)
  }
  return result
}

export { handleStake, handleUnstake, handleClaimRewards }
