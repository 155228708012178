import { useState } from 'react'
import { useMutation } from 'react-query'
import axios from 'axios'

import CONFIG from '../config.json'

const requestAirdrop = async (address: string) => {
  const { data } = await axios.post(`${CONFIG.apiURL}/airdrop-aptos-wood`, { address })
  return data
}

interface AptosAidropResponse {
  success: boolean;
  reason?: string;
}

const useAptosAirdrop = () => {
  const [data, setData] = useState<AptosAidropResponse>()
  const [error, setError] = useState<any>(null)
  const {
    mutateAsync: requestAptosAirdrop,
    isLoading,
  } = useMutation(requestAirdrop, {
    onSuccess: (result) => {
      setData(result)
    },
    onError: (e) => {
      setError(e)
    },
  })
  return {
    isLoading,
    error,
    data,
    requestAptosAirdrop,
  }
}

export default useAptosAirdrop
