import { useContext } from 'react'

import {
  AptosTokenBalancesContext,
  IAptosTokenBalancesContext,
} from '../context/AptosTokenBalancesProvider'

const useAptosTokenBalances = () => useContext<IAptosTokenBalancesContext>(
  AptosTokenBalancesContext,
)

export default useAptosTokenBalances
