import React from 'react'
import { toast } from 'react-toastify'

import {
  useTokenBalances,
  useAptosTokensBalances,
  useMultichainWallet,
  useAptosAirdrop,
} from '../../hooks'
import { ModalHeaderContainer } from '../ui'
import { CoinsIcon } from '../../icons'
import { Chains } from '../../types'
import { translate } from '../../localization'

interface ModalMobileBalancesBodyProps {
  onHide: () => void;
}

interface BalanceContainerProps {
  balance: number;
  resourceName: string;
  imgSrc?: string;
}

const BalanceContainer = ({ balance, resourceName, imgSrc }: BalanceContainerProps) => (
  <div className="balance-container">
    <div className="balance-image-and-text-container">
      <div className="balance-image-container">
        <img src={imgSrc || `../images/icons/${resourceName.toLowerCase()}.png`} alt={resourceName} width={50} />
      </div>
      <div className="balance-text-container">
        <p>{resourceName}</p>
        <div className="balance-with-icon-container">
          <CoinsIcon />
          {balance}
        </div>
      </div>
    </div>
    <div className="trade-btn-container">
      <span>{translate('trade')}</span>
    </div>
  </div>
)

const ModalMobileBalancesBody = ({ onHide }: ModalMobileBalancesBodyProps) => {
  const { balances } = useTokenBalances()
  const { balances: aptosBalances, updateBalances } = useAptosTokensBalances()
  const { address, connected, connectedChain } = useMultichainWallet()

  const { requestAptosAirdrop, isLoading } = useAptosAirdrop()

  const onRequestAirdrop = async () => {
    const result = await requestAptosAirdrop(String(address))

    if (result.success) {
      toast.success(translate('airdropReceived'))

      updateBalances()
    } else {
      toast.error(translate(String(result.reason)))
    }
  }

  return (
    <>
      <ModalHeaderContainer
        title={translate('balances')}
        onClose={onHide}
      />

      <div className="modal-container">
        <div className="modal-body-container modal-body-workers modal-body-mobile-balances">
          <BalanceContainer
            resourceName={translate('wood').toUpperCase()}
            balance={balances?.wood || aptosBalances?.wood || 0}
          />
          <BalanceContainer
            resourceName={translate('stone').toUpperCase()}
            balance={balances?.stone || aptosBalances?.stone || 0}
          />
          <BalanceContainer
            resourceName={translate('iron').toUpperCase()}
            balance={balances?.iron || aptosBalances?.iron || 0}
          />
          <BalanceContainer
            resourceName={translate('wheat').toUpperCase()}
            balance={balances?.wheat || aptosBalances?.wheat || 0}
          />
          {connected && connectedChain === Chains.aptos ? (
            <button
              disabled={isLoading}
              type="button"
              className={`airdrop-button ${isLoading && 'disabled'}`}
              onClick={onRequestAirdrop}
            >
              {isLoading && <img src="../images/loading.svg" alt="loading spinner" />}
              <span>AIRDROP WOOD</span>
            </button>
          ) : (
            <BalanceContainer
              resourceName={translate('meta').toUpperCase()}
              imgSrc="../images/metaversium.png"
              balance={balances?.meta || aptosBalances?.meta || 0}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ModalMobileBalancesBody
