import React, { useState } from 'react'
import { Modal } from '@mui/material'

import { ResourceType } from '../types'
import ModalBuildings from './ModalBuildings'
import ModalWorkersBody from './ModalWorkers/ModalWorkersBody'

interface ModalBuildingProps {
  isOpen: boolean;
  handleClose: () => void,
  buildingType: string;
}

const ModalStateType = {
  buildings: 'buildings',
  workers: 'workers',
} as const

type ModalStateTypes = typeof ModalStateType[keyof typeof ModalStateType]

const ModalRoot = ({
  isOpen, handleClose, buildingType,
}: ModalBuildingProps) => {
  const [modalState, setModalState] = useState<ModalStateTypes>(ModalStateType.buildings)
  const [buildingLevel, setBuildingLevel] = useState(0)

  const onFarm = (nextBuildingLevel: number) => {
    setBuildingLevel(nextBuildingLevel)
    setModalState(ModalStateType.workers)
  }

  const onHide = () => {
    handleClose()
    setModalState(ModalStateType.buildings)
  }

  return (
    <Modal
      open={isOpen}
      onClose={onHide}
      disableEnforceFocus={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`modal-root-${modalState}`}
    >
      <>
        {modalState === 'buildings' ? (
          <ModalBuildings
            onHide={onHide}
            buildingType={ResourceType[buildingType]}
            onFarm={onFarm}
          />
        ) : (
          <ModalWorkersBody
            buildingLevel={buildingLevel}
            onHide={onHide}
            type="farm"
            farmType={ResourceType[buildingType]}
          />
        )}
      </>
    </Modal>
  )
}

export default ModalRoot
