import React from 'react'

function TheeDots() {
  return (
    <svg width="24" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="2.5" r="2.5" fill="white" />
      <circle cx="5" cy="10" r="2.5" fill="white" />
      <circle cx="5" cy="17.5" r="2.5" fill="white" />
    </svg>
  )
}

export default TheeDots
