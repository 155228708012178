import React from 'react'
import { Modal } from '@mui/material'

import ModalProfileBody from './ModalProfileBody'

interface ModalProfileBodyProps {
  isOpen: boolean;
  onHide: () => void;
}

const ModalMobileBalances = ({ isOpen, onHide }: ModalProfileBodyProps) => (
  <Modal
    open={isOpen}
    disableEnforceFocus={false}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    className="modal-root-leaderboard"
  >
    <ModalProfileBody onHide={onHide} />
  </Modal>
)

export default ModalMobileBalances
