import * as React from 'react'

const CoinsIcon = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.861 18.834c-1.777-1.599-4.96-2.664-8.59-2.664s-6.812 1.065-8.59 2.664h-1.5c0 1.648.161 2.208.188 2.944.112 3.095 4.33 5.608 9.902 5.608 5.573 0 9.872-2.513 9.997-5.608.03-.738.094-1.17.094-2.951l-1.5.007Z"
      fill="#C6640C"
    />
    <path
      d="M20.306 22.39c-.117 2.114 0 4.52 0 4.52 2.118-.528 3.827-1.461 4.864-2.626 1.192-1.66 1.004-3.386 1.004-5.012 0 0-5.758 1.142-5.868 3.119Z"
      fill="#A3490A"
    />
    <path
      d="m14.685 27.316-.215-2.933-1.05-3.452-6.266-1.204.213 4.468c.496.593 1.163 1.127 1.968 1.582l.03-3.925 2.727 1.372.166.994-.055 2.68c.775.198 1.606.34 2.482.418Z"
      fill="#DD8D19"
    />
    <path
      d="M26.362 18.827c0 3.097-4.518 5.9-10.09 5.9-5.574 0-10.091-2.796-10.091-5.893s4.517-5.608 10.09-5.608c5.573 0 10.091 2.503 10.091 5.6Z"
      fill="url(#a)"
    />
    <path
      opacity={0.7}
      d="m10.691 19.279-2.359-3.907c-.702.497-1.256 1.06-1.626 1.672l5.552 7.175a16.43 16.43 0 0 0 2.092.395l-3.659-5.335Z"
      fill="#FCFFAC"
    />
    <path
      d="m13.44 15.88.428-.28-1.395-1.963c-.266.06-.526.126-.78.198l1.747 2.045Z"
      fill="#FCFFAC"
    />
    <path
      opacity={0.7}
      d="m20.807 16.707 4.204 5.005c.25-.258.42-.46.644-.772l-6.733-7.518a17.41 17.41 0 0 0-1.422-.155l3.307 3.44Z"
      fill="#FCFFAC"
    />
    <path
      d="M16.46 23.224c4.155 0 7.524-1.872 7.524-4.18 0-2.31-3.369-4.182-7.524-4.182-4.155 0-7.523 1.872-7.523 4.181 0 2.31 3.368 4.181 7.523 4.181Z"
      fill="#FCFFAC"
    />
    <path
      d="M16.271 23.038c4.178 0 7.565-1.882 7.565-4.204s-3.387-4.204-7.565-4.204c-4.178 0-7.565 1.882-7.565 4.204s3.387 4.204 7.565 4.204Z"
      fill="#FBAA12"
    />
    <path
      opacity={0.3}
      d="M22.463 16.418c.517.568.808 1.211.808 1.892 0 2.322-3.387 4.204-7.565 4.204-2.56 0-4.822-.706-6.191-1.787 1.246 1.37 3.804 2.311 6.757 2.311 4.178 0 7.565-1.882 7.565-4.204 0-.9-.509-1.733-1.374-2.416Z"
      fill="#EA831C"
    />
    <path
      opacity={0.25}
      d="M6.492 17.446c.946 1.724 3.83 2.978 7.243 2.978 4.18 0 7.57-1.883 7.57-4.207 0-1.021-.655-1.958-1.745-2.686a17.522 17.522 0 0 0-3.289-.305c-4.71 0-8.667 1.794-9.779 4.22Z"
      fill="#FFFFEB"
    />
    <path
      d="M17.137 18.886c1.33.057 4.722.335 2.478 1.674-2.352 1.403-3.042.136-3.296-.916-.254 1.052-.943 2.319-3.295.916-2.245-1.339 1.148-1.617 2.478-1.674-1.33-.057-4.723-.335-2.478-1.674 2.352-1.402 2.947-.01 3.2 1.043.255-1.052 1.038-2.446 3.39-1.043 2.245 1.34-1.147 1.617-2.477 1.674Z"
      fill="#C6640C"
    />
    <path
      d="M17.042 18.781c1.33.057 4.723.335 2.478 1.674-2.352 1.403-3.041.136-3.295-.916-.254 1.052-.944 2.319-3.296.916-2.244-1.339 1.148-1.617 2.478-1.674-1.33-.057-4.722-.335-2.478-1.673 2.352-1.403 3.042-.137 3.296.916.253-1.053.943-2.32 3.295-.916 2.245 1.338-1.148 1.616-2.478 1.673Z"
      fill="#FCE13C"
    />
    <path
      d="M14.637 18.83c-1.315.11-3.07.41-2.24 1.233-.719-.914 2.564-1.204 2.24-1.233ZM16.319 18.129c.254-1.053.943-2.32 3.295-.917.239.143.413.273.534.392-.116-.147-.317-.311-.628-.496-2.26-1.348-2.984-.232-3.263.791.023.077.043.154.062.23ZM17.907 18.838c-.288.024 1.792.213 2.306.656-.258-.392-1.372-.573-2.306-.656ZM13.023 17.212c1.44-.858 2.257-.717 2.732-.236-.459-.561-1.286-.787-2.826.132-.801.478-.884.82-.599 1.066-.155-.236 0-.548.693-.962Z"
      fill="#FCFFAC"
    />
    <path
      opacity={0.25}
      d="M19.327 19.053c-.726-.18-1.703-.247-2.285-.272 1.33-.057 4.723-.335 2.478-1.673-2.352-1.403-3.041-.137-3.295.916-.254-1.053-.944-2.32-3.296-.916-2.244 1.338 1.148 1.616 2.478 1.673-1.31.056-4.62.327-2.576 1.614a13.583 13.583 0 0 0 3.148-.158 3.27 3.27 0 0 0 .246-.698c.049.203.114.413.204.611 1.117-.236 2.107-.615 2.898-1.096Z"
      fill="#FFFFEB"
    />
    <path
      d="M8.894 18.99c0-2.321 3.388-4.204 7.565-4.204 2.568 0 4.836.711 6.204 1.798-1.342-1.175-3.703-1.955-6.392-1.955-4.178 0-7.565 1.882-7.565 4.205 0 .895.503 1.724 1.362 2.407-.743-.65-1.174-1.422-1.174-2.25Z"
      fill="#CF7310"
    />
    <path
      opacity={0.5}
      d="M9.806 20.099c0 2.025 2.14 3.772 5.231 4.582.405.03.816.046 1.234.046 5.573 0 10.091-2.803 10.091-5.9 0-.776-.283-1.514-.796-2.186-1.662-.987-4.027-1.604-6.652-1.604-5.03 0-9.108 2.266-9.108 5.062Z"
      fill="#916C5D"
    />
    <path
      d="M22.459 14.206c-1.778-1.599-4.96-2.664-8.59-2.664s-6.812 1.065-8.59 2.664h-1.5c0 1.648.161 2.208.188 2.944.112 3.095 4.329 5.608 9.902 5.608 5.573 0 9.872-2.513 9.996-5.608.03-.738.094-1.17.094-2.951l-1.5.007Z"
      fill="#C6640C"
    />
    <path
      d="M17.904 17.763c-.118 2.113 0 4.52 0 4.52 2.117-.529 3.826-1.462 4.863-2.627 1.192-1.66 1.005-3.387 1.005-5.012 0 0-5.758 1.142-5.868 3.119Z"
      fill="#A3490A"
    />
    <path
      d="m12.282 22.688-.216-2.933-1.049-3.452L4.751 15.1l.213 4.468c.496.592 1.163 1.127 1.968 1.581l.03-3.925 2.727 1.372.166.994-.055 2.68c.775.198 1.606.34 2.482.418Z"
      fill="#DD8D19"
    />
    <path
      d="M23.96 14.198c0 3.098-4.518 5.9-10.091 5.9-5.573 0-10.09-2.795-10.09-5.892 0-3.098 4.517-5.608 10.09-5.608 5.573 0 10.09 2.504 10.09 5.6Z"
      fill="url(#b)"
    />
    <path
      opacity={0.7}
      d="m8.288 14.651-2.359-3.907c-.702.497-1.256 1.06-1.626 1.672l5.552 7.175c.682.168 1.334.306 2.092.395l-3.66-5.335Z"
      fill="#FCFFAC"
    />
    <path
      d="m11.036 11.252.43-.28L10.07 9.01c-.266.06-.526.126-.78.198l1.746 2.045Z"
      fill="#FCFFAC"
    />
    <path
      opacity={0.7}
      d="m18.405 12.08 4.203 5.004c.25-.258.42-.46.644-.772L16.52 8.794c-.462-.07-.937-.122-1.422-.154l3.308 3.44Z"
      fill="#FCFFAC"
    />
    <path
      d="M14.058 18.596c4.155 0 7.523-1.872 7.523-4.18 0-2.31-3.368-4.182-7.523-4.182-4.155 0-7.524 1.872-7.524 4.181 0 2.31 3.369 4.181 7.524 4.181Z"
      fill="#FCFFAC"
    />
    <path
      d="M13.869 18.41c4.178 0 7.565-1.882 7.565-4.204S18.047 10 13.869 10c-4.178 0-7.565 1.883-7.565 4.205s3.387 4.204 7.565 4.204Z"
      fill="#FBAA12"
    />
    <path
      opacity={0.3}
      d="M20.061 11.79c.517.569.808 1.212.808 1.892 0 2.322-3.387 4.205-7.565 4.205-2.56 0-4.823-.707-6.192-1.788 1.246 1.37 3.804 2.311 6.757 2.311 4.178 0 7.565-1.882 7.565-4.204 0-.9-.508-1.732-1.373-2.416Z"
      fill="#EA831C"
    />
    <path
      opacity={0.25}
      d="M4.09 12.818c.946 1.724 3.83 2.979 7.242 2.979 4.181 0 7.57-1.884 7.57-4.208 0-1.021-.655-1.958-1.744-2.686a17.525 17.525 0 0 0-3.29-.305c-4.71 0-8.667 1.794-9.778 4.22Z"
      fill="#FFFFEB"
    />
    <path
      d="M14.733 14.258c1.33.057 4.722.335 2.477 1.674-2.352 1.403-3.041.136-3.295-.916-.254 1.052-.943 2.319-3.295.916-2.245-1.339 1.148-1.617 2.477-1.674-1.33-.057-4.722-.335-2.477-1.674 2.352-1.402 2.947-.01 3.2 1.043.255-1.052 1.038-2.446 3.39-1.043 2.245 1.34-1.147 1.617-2.477 1.674Z"
      fill="#C6640C"
    />
    <path
      d="M14.64 14.154c1.329.057 4.721.335 2.477 1.673-2.352 1.403-3.042.136-3.296-.916-.254 1.052-.943 2.32-3.295.916-2.245-1.338 1.148-1.616 2.477-1.673-1.33-.057-4.722-.335-2.477-1.674 2.352-1.403 3.041-.136 3.295.916.254-1.052.944-2.319 3.296-.916 2.244 1.338-1.148 1.617-2.478 1.674Z"
      fill="#FCE13C"
    />
    <path
      d="M12.233 14.202c-1.314.11-3.07.41-2.24 1.233-.717-.914 2.565-1.204 2.24-1.233ZM13.915 13.5c.254-1.052.944-2.318 3.296-.916.239.143.413.273.534.392-.115-.147-.318-.311-.628-.496-2.26-1.348-2.985-.232-3.264.791.024.077.044.154.062.23ZM15.504 14.21c-.289.024 1.792.213 2.306.656-.258-.392-1.373-.573-2.306-.656ZM10.62 12.584c1.44-.858 2.256-.717 2.732-.236-.46-.561-1.287-.787-2.826.132-.802.478-.884.82-.6 1.066-.154-.236 0-.548.694-.962Z"
      fill="#FCFFAC"
    />
    <path
      opacity={0.25}
      d="M16.924 14.426c-.727-.18-1.703-.247-2.285-.272 1.33-.057 4.722-.335 2.478-1.674-2.352-1.403-3.042-.136-3.296.916-.254-1.052-.943-2.319-3.295-.916-2.245 1.339 1.148 1.617 2.477 1.674-1.31.056-4.619.326-2.576 1.613a13.583 13.583 0 0 0 3.149-.158c.112-.223.19-.465.245-.698.05.203.114.414.204.612 1.117-.237 2.107-.616 2.899-1.097Z"
      fill="#FFFFEB"
    />
    <path
      d="M6.492 14.363c0-2.322 3.387-4.204 7.565-4.204 2.567 0 4.836.71 6.203 1.797C18.92 10.78 16.557 10 13.87 10c-4.178 0-7.565 1.883-7.565 4.205 0 .895.503 1.725 1.361 2.407-.743-.65-1.173-1.423-1.173-2.25Z"
      fill="#CF7310"
    />
    <path
      opacity={0.5}
      d="M23.711 15.455c-.514-2.02-3.585-3.652-7.35-3.777-4.175-.139-7.622 1.63-7.698 3.951-.07 2.176 2.847 4.065 6.654 4.406 4.148-.368 7.482-2.282 8.394-4.58Z"
      fill="#916C5D"
    />
    <path
      d="M26.47 11.011c-1.724-1.656-4.87-2.827-8.498-2.947-3.628-.12-6.843.839-8.672 2.377l-1.5-.05c-.054 1.648.09 2.213.092 2.95.012 3.096 4.144 5.748 9.714 5.933 5.57.185 9.948-2.185 10.174-5.273.054-.737.132-1.167.19-2.947l-1.5-.043Z"
      fill="#C6640C"
    />
    <path
      d="M21.801 14.415c-.186 2.108-.147 4.517-.147 4.517 2.133-.457 3.872-1.334 4.946-2.463 1.246-1.62 1.115-3.352 1.168-4.976 0 0-5.792.95-5.967 2.922Z"
      fill="#A3490A"
    />
    <path
      d="m16.023 19.151-.12-2.938-.937-3.485-6.223-1.41.067 4.472c.477.608 1.125 1.165 1.915 1.646l.159-3.922 2.68 1.462.134 1-.143 2.676c.768.222 1.595.392 2.468.499Z"
      fill="#DD8D19"
    />
    <path
      d="M27.97 11.054c-.1 3.095-4.707 5.746-10.277 5.561-5.57-.185-9.994-3.128-9.892-6.223.1-3.096 4.698-5.455 10.268-5.27 5.57.185 10.003 2.836 9.902 5.932Z"
      fill="url(#c)"
    />
    <path
      opacity={0.7}
      d="m12.293 10.986-2.23-3.983c-.717.473-1.29 1.019-1.68 1.617l5.315 7.356c.677.19 1.324.35 2.078.464l-3.482-5.454Z"
      fill="#FCFFAC"
    />
    <path
      d="m15.151 7.68.438-.266-1.33-2.008c-.268.052-.53.109-.786.172L15.15 7.68Z"
      fill="#FCFFAC"
    />
    <path
      opacity={0.7}
      d="m22.488 8.752 4.038 5.14a6.13 6.13 0 0 0 .67-.749L20.71 5.405a17.28 17.28 0 0 0-1.416-.2l3.193 3.547Z"
      fill="#FCFFAC"
    />
    <path
      d="M25.587 11.19c.076-2.307-3.23-4.29-7.383-4.428-4.153-.138-7.581 1.622-7.656 3.93-.076 2.307 3.23 4.29 7.383 4.428 4.153.138 7.58-1.622 7.656-3.93Z"
      fill="#FCFFAC"
    />
    <path
      d="M25.447 10.976c.076-2.32-3.248-4.314-7.424-4.453-4.176-.138-7.623 1.631-7.698 3.952-.076 2.32 3.248 4.314 7.424 4.453 4.176.138 7.622-1.63 7.698-3.952Z"
      fill="#FBAA12"
    />
    <path
      opacity={0.3}
      d="M24.153 8.517c.498.586.768 1.238.745 1.919-.075 2.32-3.522 4.089-7.698 3.95-2.558-.084-4.797-.865-6.13-1.992 1.2 1.412 3.726 2.437 6.678 2.535 4.176.138 7.622-1.63 7.698-3.952.03-.898-.451-1.748-1.293-2.46Z"
      fill="#EA831C"
    />
    <path
      opacity={0.25}
      d="M8.157 9.015c.889 1.754 3.731 3.104 7.142 3.217 4.178.139 7.628-1.631 7.703-3.954.034-1.02-.59-1.979-1.656-2.743a17.525 17.525 0 0 0-3.277-.414c-4.708-.156-8.722 1.506-9.912 3.894Z"
      fill="#FFFFEB"
    />
    <path
      d="M18.748 10.807c1.327.101 4.709.492 2.421 1.755-2.396 1.325-3.044.035-3.264-1.025-.288 1.044-1.018 2.287-3.323.807-2.2-1.413 1.2-1.578 2.531-1.591-1.327-.101-4.709-.492-2.421-1.755 2.396-1.324 2.945.088 3.165 1.149.288-1.044 1.117-2.41 3.422-.93 2.2 1.412-1.2 1.577-2.531 1.59Z"
      fill="#C6640C"
    />
    <path
      d="M18.657 10.7c1.327.1 4.709.491 2.421 1.755-2.396 1.324-3.044.035-3.263-1.025-.289 1.043-1.02 2.286-3.324.806-2.2-1.413 1.2-1.578 2.531-1.59-1.327-.102-4.709-.493-2.421-1.756 2.396-1.324 3.044-.035 3.263 1.025.289-1.043 1.02-2.287 3.324-.806 2.2 1.412-1.2 1.578-2.531 1.59Z"
      fill="#FCE13C"
    />
    <path
      d="M16.251 10.668c-1.318.066-3.083.309-2.28 1.159-.687-.938 2.604-1.12 2.28-1.159ZM17.956 10.023c.288-1.044 1.019-2.286 3.324-.807.233.15.404.286.52.41-.11-.15-.307-.322-.611-.517-2.215-1.422-2.976-.33-3.288.683.02.077.04.155.055.23ZM19.52 10.784c-.29.015 1.784.273 2.283.732-.245-.4-1.353-.617-2.283-.732ZM14.692 8.998c1.467-.81 2.278-.642 2.738-.146-.441-.576-1.26-.829-2.83.038-.816.451-.91.79-.633 1.046-.147-.24.018-.548.725-.938Z"
      fill="#FCFFAC"
    />
    <path
      opacity={0.25}
      d="M20.931 11.047c-.72-.204-1.694-.303-2.274-.347 1.331-.013 4.73-.178 2.531-1.591-2.305-1.48-3.035-.237-3.323.806-.22-1.06-.868-2.349-3.264-1.025-2.287 1.264 1.094 1.654 2.421 1.755-1.31.013-4.627.174-2.627 1.527a13.227 13.227 0 0 0 3.152-.054c.119-.218.204-.458.267-.689.043.205.1.418.184.618 1.125-.198 2.127-.545 2.933-1Z"
      fill="#FFFFEB"
    />
    <path
      d="M10.508 10.639c.076-2.321 3.523-4.09 7.698-3.952 2.566.086 4.81.871 6.141 2.003-1.302-1.219-3.636-2.076-6.324-2.166-4.176-.138-7.623 1.63-7.698 3.952-.03.894.446 1.74 1.282 2.45-.721-.675-1.126-1.46-1.099-2.287Z"
      fill="#CF7310"
    />
    <defs>
      <radialGradient
        id="a"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(8.19643 0 0 8.26166 16.271 18.976)"
      >
        <stop stopColor="#FBC01E" />
        <stop offset={0.461} stopColor="#FDC822" />
        <stop offset={1} stopColor="#FCE13C" />
      </radialGradient>
      <radialGradient
        id="b"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(8.19644 0 0 8.26166 13.869 14.348)"
      >
        <stop stopColor="#FBC01E" />
        <stop offset={0.461} stopColor="#FDC822" />
        <stop offset={1} stopColor="#FCE13C" />
      </radialGradient>
      <radialGradient
        id="c"
        cx={0}
        cy={0}
        r={1}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(8.192 .2718 -.27396 8.25705 17.88 10.869)"
      >
        <stop stopColor="#FBC01E" />
        <stop offset={0.461} stopColor="#FDC822" />
        <stop offset={1} stopColor="#FCE13C" />
      </radialGradient>
    </defs>
  </svg>
)

export default CoinsIcon
