import React, { useState } from 'react'
// import { toast } from 'react-toastify'

import { GradientButton, ModalHeaderContainer, TextInput } from '../ui'
// import { usePlayerUsername } from '../../hooks'
import { ProfileIcon } from '../../icons'
import { translate } from '../../localization'

interface ModalMobileBalancesBodyProps {
  onHide: () => void;
}

const ModalProfileBody = ({ onHide }: ModalMobileBalancesBodyProps) => {
  const [username, setUserName] = useState('')
  const [discord, setDiscord] = useState('')
  const [twitter, setTwitter] = useState('')

  // const { register } = usePlayerUsername()

  return (
    <>
      <ModalHeaderContainer
        title={translate('profile')}
        onClose={onHide}
      />

      <div className="modal-container">
        <div className="modal-body-container modal-body-workers modal-body-profile">
          <div className="input-raw-container">
            <div className="input-username-container">
              <ProfileIcon />
              <span>{translate('username')}</span>
            </div>
            <TextInput value={username} onChange={(e) => setUserName(e.target.value)} placeholder="@username" />
            <GradientButton text={translate('claim')} onClick={() => console.log('test')} />
          </div>
          <div className="input-raw-container">
            <div>
              <span className="fab fa-discord" />
              <span>Discord</span>
            </div>
            <TextInput
              value={discord}
              onChange={(e) => setDiscord(e.target.value)}
              placeholder="@discord"
            />
            <GradientButton
              isDisabled
              text={translate('add')}
              onClick={() => console.log('send request to backed')}
            />
          </div>
          <div className="input-raw-container">
            <div>
              <span className="fab fa-twitter" />
              <span>Twitter</span>
            </div>
            <TextInput
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
              placeholder="@twitter"
            />
            <GradientButton
              isDisabled
              text={translate('add')}
              onClick={() => console.log('send request to backed')}
            />
          </div>
          <div className="divider" />
          <div className="referal-container">
            <span>Refferal code:</span>
            <p>8sd7ga-af12g1-p97gq3-qw88dcv</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalProfileBody
