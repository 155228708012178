import React from 'react'
import { Modal } from '@mui/material'

import ModalWorkersBody from './ModalWorkersBody'

interface ModalWorkersProps {
  isOpen: boolean;
  onHide: () => void;
}

const ModalWorkers = ({ isOpen, onHide }: ModalWorkersProps) => (
  <Modal
    open={isOpen}
    disableEnforceFocus={false}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    style={{ maxHeight: '80%' }}
  >
    <ModalWorkersBody
      onHide={onHide}
      type="main"
      farmType=""
    />
  </Modal>
)

export default ModalWorkers
