/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { MenuProps } from '@mui/material'

import { ModalStatusTypes } from '../Navigations'
import { Chains } from '../../types'
import { useMultichainWallet } from '../../hooks'
import MobileMenu from './MobileMenu'
import SelectMultichainWallet from './SelectMultiChainWallet'
import QuestsDropDrown from './QuestsDropDown'
import { translate } from '../../localization'

interface TopNavigationProps {
  handleModal: (modalType: ModalStatusTypes) => void;
}

const { REACT_APP_MULTICHAIN } = process.env

const TopNavigation = ({ handleModal }: TopNavigationProps) => {
  const [selectWalletVis, setSelectWalletVis] = useState(false)
  const [isMobMenuVis, setIsMobMenuVis] = useState(false)
  const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl']>(null)

  const {
    wallet, disconnect, connectedChain,
  } = useMultichainWallet()

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <>
      <div className="top-navigation">
        <div>
          <img src="../images/icons/logo.png" alt="Metaversium Logo" width="167" />
        </div>
        <div
          tabIndex={0}
          role="button"
          className="hamburger"
          onClick={() => setIsMobMenuVis(!isMobMenuVis)}
        >
          {isMobMenuVis ? (
            <img src="../images/icons/closeIcon.png" alt="Close Icon" />
          ) : (
            <img src="../images/icons/hamburgerIcon.png" alt="Hamburger Icon" />
          )}
        </div>
        <div className="nav-items">
          <div className="nav-item non-active">
            <img src="../images/icons/atlas.png" width="32" alt="" />
            <p>{`${translate('map')}`}</p>
          </div>
          <div className="nav-item" onClick={() => handleModal('leaderboard')}>
            <img src="../images/icons/leaderboard.png" width="32" alt="" />
            <p>{translate('leaderboard')}</p>
          </div>
          <div className="nav-item workers-button" onClick={() => handleModal('workers')}>
            <img src="../images/icons/worker.png" width="32" alt="" />
            <p>{translate('workers')}</p>
          </div>
          <div
            className="nav-item quests-button"
            onClick={(e) => handleClick(e)}
            onMouseOver={(e) => handleClick(e)}
            onFocus={(e) => handleClick(e)}
          >
            <img src="../images/icons/quest.png" width="32" alt="" />
            <p>{translate('quests')}</p>
          </div>
          {wallet && (
            <div className="nav-item" onClick={() => handleModal('profile')}>
              <img src="../images/icons/profile.png" width="32" alt="" />
              <p>{translate('profile')}</p>
            </div>
          )}
          <div className="nav-item">
            {wallet ? (
              connectedChain === Chains.solana ? <WalletDisconnectButton /> : (
                <button type="button" className="disconnect-chain-button" onClick={() => disconnect()}>
                  <img src={'icon' in wallet ? wallet?.icon : ''} alt="wallet" width="24" />
                  <span>{translate('disconnect')}</span>
                </button>
              )
            ) : (
              REACT_APP_MULTICHAIN ? (
                <div className="nav-item">
                  <button type="button" className="select-chain-button" onClick={() => setSelectWalletVis(true)}>
                    <span>{translate('connect')}</span>
                  </button>
                </div>
              ) : <WalletMultiButton />
            )}
          </div>
          <QuestsDropDrown
            handleModal={handleModal}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
          />
          <SelectMultichainWallet
            selectWalletVis={selectWalletVis}
            onHide={() => setSelectWalletVis(false)}
          />
        </div>
      </div>
      <MobileMenu
        handleModal={handleModal}
        isMobMenuVis={isMobMenuVis}
        setSelectWalletVis={setSelectWalletVis}
      />
    </>
  )
}

export default TopNavigation
