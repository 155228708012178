import { useQuery } from 'react-query'

import { Quest } from 'types'
import useMultichainWallet from './useMultichainWallet'
import CONFIG from '../config.json'

interface QuestResponse {
  status: string;
  quests: Quest[];
}

const useQuests = (questsType) => {
  const { address } = useMultichainWallet()
  const { isLoading, error, data } = useQuery<QuestResponse>(
    ['quests', address],
    () => fetch(`${CONFIG.apiURL}/quests?address=${address}&type=${questsType}`).then((res) => res.json()),
    { cacheTime: 0, staleTime: 0 },
  )
  return {
    isLoading,
    error,
    data,
  }
}

export default useQuests
