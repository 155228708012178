import React from 'react'

import { translate } from '../../localization'
import { BackIcon, CloseIcon } from '../../icons'

interface ModalHeaderContainerProps {
  title?: string;
  onClose: () => void;
}

const ModalHeaderContainer = ({ title, onClose }: ModalHeaderContainerProps) => (
  <div className="modal-header-container">
    <div className="modal-header">
      <div className="mobile-back-button" onClick={onClose}>
        <BackIcon />
        <span>{translate('back')}</span>
      </div>
      <span>{title}</span>
    </div>
    <div
      className="modal-close-icon"
      onClick={onClose}
    >
      <CloseIcon />
    </div>
  </div>
)

ModalHeaderContainer.defaultProps = {
  title: '',
}

export default ModalHeaderContainer
