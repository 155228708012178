import React, {
  useMemo, useCallback, useEffect, useState,
} from 'react'
// solana libs
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react'
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui'
import { PhantomWalletAdapter, SolflareWalletAdapter, BackpackWalletAdapter } from '@solana/wallet-adapter-wallets'
import { clusterApiUrl } from '@solana/web3.js'
// aptos libs
import { AptosWalletAdapterProvider } from '@aptos-labs/wallet-adapter-react'
import { PetraWallet } from 'petra-plugin-wallet-adapter'
import { PontemWallet } from '@pontem/wallet-adapter-plugin'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ConfettiContainer } from './components/ui'
import {
  AptosTokenBalancesProvider,
  TokenBalancesProvider,
  ConfettiProvider,
  MapProvider,
  ApiClientProvider,
} from './context'
import { BalancesSheet, Navigations, OnBoardingContainer } from './components'
import HomePage from './pages/HomePage'
import { initLocalization } from './localization'

initLocalization()

const App = () => {
  const [isOnboardingRun, setIsOnboardingRun] = useState(false)
  const network = WalletAdapterNetwork.Devnet
  const endpoint = useMemo(() => clusterApiUrl(network), [network])
  const wallets = useMemo(() => [
    new PhantomWalletAdapter(), new SolflareWalletAdapter(), new BackpackWalletAdapter()], [])
  const aptosWallets = useMemo(() => [new PetraWallet(), new PontemWallet()], [])
  // eslint-disable-next-line no-console
  const onError = useCallback((error) => console.log(error), [])

  useEffect(() => {
    async function initOnBoarding() {
      // disable onboarding for mobile users
      if (window.innerWidth < 576) return
      const isOnboardingComplete = await localStorage.getItem('onBoarding')

      if (!isOnboardingComplete) setIsOnboardingRun(true)
    }
    initOnBoarding()
  }, [])

  return (
    <ApiClientProvider>
      <ConnectionProvider endpoint={endpoint}>
        <AptosWalletAdapterProvider plugins={aptosWallets}>
          <WalletProvider wallets={wallets} onError={onError} autoConnect>
            <WalletModalProvider>
              <TokenBalancesProvider>
                <AptosTokenBalancesProvider>
                  <ConfettiProvider>
                    <MapProvider>
                      <>
                        <OnBoardingContainer isOnboardingRun={isOnboardingRun} />
                        <Navigations />
                        <HomePage />
                        <BalancesSheet />
                        <ToastContainer />
                        <ConfettiContainer />
                      </>
                    </MapProvider>
                  </ConfettiProvider>
                </AptosTokenBalancesProvider>
              </TokenBalancesProvider>
            </WalletModalProvider>
          </WalletProvider>
        </AptosWalletAdapterProvider>
      </ConnectionProvider>
    </ApiClientProvider>
  )
}

export default App
